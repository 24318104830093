<template>
  <div id="header">
    <div v-if="!isCustomer" id="header-label-block">
      <div
        class="pi pi-fw pi-bars md:mr-4 header-icon cursor-pointer lg:hidden"
        v-on:click="onToggleMobileSideBar()"
      ></div>
      <div
        class="pi pi-fw pi-bars md:mr-4 header-icon hidden lg:block cursor-pointer"
        v-on:click="onToggleSideBar()"
      ></div>
      <div id="header-route-name">{{ currentRouteName }}</div>
    </div>
    <div class="hidden md:flex">
      <img
        id="header-logo"
        alt="Parsepay logomark"
        src="../assets/images/logomark-blue@2x.png"
      />
    </div>
    <div id="header-employee-name" class="hidden md:flex">
      {{ currentUserName }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    onToggleSideBar: function () {
      this.$emit('toggleSidebar');
    },
    onToggleMobileSideBar: function () {
      this.$emit('toggleMobileSidebar');
    },
    logOut() {
      this.$store.dispatch('auth/logout');
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentUserName() {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user.name) || ''
      );
    },
  },
  data() {
    return {
      visibleLeft: true,
      isCustomer: localStorage.getItem('isCustomer'),
    };
  },
};
</script>
