<template>
  <div class="step-display">
    <div class="step-connector" />
    <div v-for="step in this.steps" v-bind:key="step.number" class="step">
      <div v-if="step.name === current" class="step-number step-number-active">
        {{ step.number }}
      </div>
      <div
        @click="this.$emit('open', step.page)"
        v-else-if="this.completed_forms.includes(step.name)"
        class="step-number step-number-complete"
      >
        <i class="pi pi-check" />
      </div>
      <div v-else class="step-number">{{ step.number }}</div>
      <div class="step-name">{{ step.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    completed_forms: Array,
    current: String,
  },
  data() {
    return {
      steps: [
        {
          number: 1,
          name: 'general',
          page: 'general',
          title: 'General Information',
        },
        {
          number: 2,
          name: 'business',
          page: 'business',
          title: 'Legal Entity Information',
        },
        {
          number: 3,
          name: 'profile',
          page: 'profile',
          title: 'Merchant Profile',
        },
        {
          number: 4,
          name: 'banking',
          page: 'banking',
          title: 'Banking',
        },
        {
          number: 5,
          name: 'businessContacts',
          page: 'contacts',
          title: 'Add Contacts',
        },
      ],
    };
  },
};
</script>
