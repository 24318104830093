import ApiService from '../api.service';

class Users {
  async getUsers() {
    return await ApiService.request('get', 'admin/user/');
  }

  async getParsePosUsers() {
    const result = await ApiService.request('get', 'parse-pos/users');

    if (result.users) {
      return { success: true, users: result.users };
    }
    if (result.error || result.message) {
      return { success: false, error: result.error ?? result.message };
    }

    return { success: false, error: 'invalid response' };
  }
}

export default new Users();
