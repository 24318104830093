<template>
  <page title="Merchants">
    <Merchants />
  </page>
</template>

<script>
import Page from '@/components/Page';
import Merchants from '../components/Merchants/Merchants';

export default {
  components: {
    Page,
    Merchants,
  },
};
</script>
