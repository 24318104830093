<template>
  <div>
    <div class="pb-4">
      <Button
        label="Back to Applications"
        icon="pi pi-chevron-left"
        class="p-button-text pp-button p-button-plain"
        @click="goBack"
      ></Button>
    </div>
    <page title="Merchant Application" clean>
      <merchant-app
        v-if="application"
        :merchant-number="this.application.merchant_number"
      />
    </page>
  </div>
</template>

<script>
import Page from '@/components/Page';
import MerchantApp from '@/components/Application/Main';
import Button from 'primevue/button';

export default {
  components: {
    MerchantApp,
    Page,
    Button,
  },
  data() {
    return {
      application: null,
    };
  },
  mounted() {
    this.loadApplication();
  },
  methods: {
    loadApplication() {
      this.application =
        this.$store.getters['merchantApplication/getApplication'];
      if (!this.application.merchant_number) {
        this.$router.push({ name: 'Applications' });
      }
    },
    goBack() {
      this.$router.push({ name: 'Applications' });
    },
  },
};
</script>

<style lang="scss" scoped>
.pp-button.p-button:enabled {
    background: transparent;
    border: transparent;
}
</style>