<template>
  <div>
    <Dialog
      id="add-equipment-modal-1"
      header="Add Equipment"
      v-model:visible="isOpen"
      :breakpoints="{
        '1600px': '40vw',
        '1400px': '50vw',
        '960px': '75vw',
        '769px': '95vw',
      }"
      :style="{ width: '30vw' }"
      @hide="hide()"
    >
      <div v-if="isLoading" class="text-center"><progress-spinner /></div>
      <small v-if="error" class="p-error">{{ error }}</small>

      <div v-if="equipments.length" class="grid m-0 flex flex-1">
        <div class="col-12 px-0 lg:px-2 mb-1">
          <div class="step-header">Select a credit card reader.</div>
        </div>

        <div
          v-for="device in this.equipments"
          v-bind:key="device.name"
          class="col-12 md:col-6 px-0 lg:px-2 mb-2"
        >
          <div class="card-reader-option">
            <div class="p-field-radiobutton">
              <RadioButton
                :id="device.name"
                :value="device.name"
                v-model="equipment"
              />
              <label :for="device.name">{{ device.name }}</label>
            </div>
          </div>
        </div>
        
        <div class="col-12 px-0 lg:px-2">
          <div class="flex align-items-center">
            <div class="mr-3">Quantity:</div>
            <div class="incrementer">
              <Button class="incrementer-btn incrementer-minus"
                ><i class="pi pi-minus"
              /></Button>
              <InputText id="accountName" type="number" v-model="quantity" />
              <Button class="incrementer-btn incrementer-plus"
                ><i class="pi pi-plus"
              /></Button>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="flex justify-content-end">
          <Button
            @click="hide()"
            :disabled="isLoading"
            label="Cancel"
            class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"
          />
          <Button
            @click="selectEquipment()"
            :disabled="isLoading || !equipment || !quantity"
            label="Configure"
            class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import EquipmentsService from '../../../services/api-calls/equipments.service';
import FormHelper from '@/services/form.helper';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: 'Equipment',
  components: {
    InputText,
    Button,
    Dialog,
    RadioButton,
    ProgressSpinner,
  },
  updated() {
    if (this.isActive !== this.isOpen) {
      this.isOpen = this.isActive;
    }
  },
  props: {
    isActive: Boolean,
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.getEquipments();
      }
    },
  },
  methods: {
    hide() {
      if (!this.isLoading) {
        this.equipments = [];
        this.equipment = null;
        this.quantity = null;
        this.$emit('close');
      }
    },
    async getEquipments() {
      this.isLoading = true;
      const result = await EquipmentsService.getAll();
      this.equipments = result.equipments ?? [];
      this.error = result.error ? FormHelper.convertGatewayError(result) : null;
      this.isLoading = false;
    },
    async selectEquipment() {
      this.$emit('selectEquipment', {
        equipment: this.equipment,
        quantity: this.quantity,
      });
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      error: null,
      equipments: [],
      equipment: null,
      quantity: 1,
      errors: {},
    };
  },
};
</script>
