<template>
  <div id="app">
    <Header
      @toggleSidebar="toggleSidebar"
      @toggleMobileSidebar="toggleMobileSidebar"
    ></Header>
    <div id="app-body">
      <Sidebar
        :sidebar-is-open="sidebarIsOpen"
        :mobileSidebarIsOpen="mobileSidebarIsOpen"
        @update:closeMobileSidebar="closeMobileSidebar"
        @update:closeSidebar="closeSidebar"
      />
      <div id="app-content">
        <router-view></router-view>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header';
import Sidebar from './Sidebar';

export default {
  components: {
    Sidebar,
    Header,
  },
  data() {
    return {
      sidebarIsOpen: true,
      mobileSidebarIsOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
    toggleMobileSidebar() {
      this.mobileSidebarIsOpen = !this.mobileSidebarIsOpen;
    },
    closeMobileSidebar() {
      this.mobileSidebarIsOpen = false;
    },
    closeSidebar() {
      this.sidebarIsOpen = false;
    },
  },
};
</script>
