<template>
  <div class="mb-4">
    <div id="applications-list-toolbar">
      <div
        class="flex flex-column-reverse lg:flex-row justify-content-between align-items-start md:align-items-center"
      >
        <Filter
          :options="options"
          @clearFilter="clearFilter()"
          @applyFilter="applyFilter()"
          @changeFilter="changeFilter($event)"
        />
        <div class="flex sm:w-full md:w-auto mb-3 lg:mb-0 w-full md:w-auto">
          <Button
            @click="orderModal = true"
            class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center"
          >
            Create New Form
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div id="applications-list-table">
    <DataTable
      :value="items"
      responsiveLayout="scroll"
      :lazy="true"
      :paginator="true"
      :rows="itemsPerPage"
      :totalRecords="total"
      :loading="loading"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      class="pp-table"
    >
      <template #empty>
        <div class="p-3">No Orders found</div>
      </template>

      <Column class="mobile-hide" field="created_at" header="Date">
        <template #body="slotProps">
          <div class="p-3">
            {{ slotProps.data.created_at }}
          </div>
        </template>
      </Column>

      <Column field="business_name" header="Business name">
        <template #body="slotProps">
          <div class="p-3">
            {{ slotProps.data.business_name }}
          </div>
        </template>
      </Column>

      <Column class="mobile-hide" field="created_by" header="Employee/Agent">
        <template #body="slotProps">
          <div class="p-3">
            {{ slotProps.data.created_by }}
          </div>
        </template>
      </Column>

      <Column
        class="mobile-hide"
        field="merchants_first_name"
        header="Contact name"
      >
        <template #body="slotProps">
          <div class="p-3">
            {{ slotProps.data.merchants_first_name }}
            {{ slotProps.data.merchants_last_name }}
          </div>
        </template>
      </Column>

      <Column
        class="mobile-hide"
        field="payment_method"
        header="Payment Method"
      >
        <template #body="slotProps">
          <div class="p-3">
            {{ this.paymentMethods[slotProps.data.payment_method] }}
          </div>
        </template>
      </Column>

      <Column class="mobile-show" field="price" header="Amount">
        <template #body="slotProps">
          <div class="p-3">
            {{ formatPrice(slotProps.data.total) }}
          </div>
        </template>
      </Column>

      <Column field="status" header="Status">
        <template #body="slotProps">
          <div class="flex justify-content-between align-items-center p-3">
            <Chip
              :label="status_chips[slotProps.data.status].label"
              :class="`pp-chip pp-chip-${slotProps.data.status}`"
              @click="
                showOrderActions(
                  slotProps.data.id,
                  slotProps.data.status,
                  $event
                )
              "
            />
          </div>
        </template>
      </Column>

      <Column class="mobile-hide" field="price" header="Amount">
        <template #body="slotProps">
          <div class="p-3">
            {{ formatPrice(slotProps.data.total) }}
          </div>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <i
            @click="
              showOrderActions(slotProps.data.id, slotProps.data.status, $event)
            "
            class="mobile-hide pi pi-fw pi-ellipsis-v table-icon cursor-pointer"
          />
          <i
            @click="
              showOrderActions(slotProps.data.id, slotProps.data.status, $event)
            "
            class="mobile-show pi pi-fw pi-ellipsis-h table-icon cursor-pointer"
          />
        </template>
      </Column>
    </DataTable>

    <create-form
      @order="getItems"
      @close="
        orderModal = false;
        this.order = {};
      "
      v-if="orderModal"
      :error="error"
      :errors="errors"
      :is-loading="loading"
      :order="order"
    />
  </div>

  <OverlayPanel ref="orderActions">
    <OrderOverlayPanel
      :is-admin="is_admin"
      :active-order-status="activeOrderStatus"
      @setOrderStatus="setOrderStatus"
      @showOrder="showOrder"
      @goQuote="goQuote"
    />
  </OverlayPanel>
</template>

<script>
import OrdersService from '@/services/api-calls/orders.service';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Helper from '@/services/helper';
import Filter from '../Filter';
import Button from 'primevue/button';
import CreateForm from './CreateForm';
import OverlayPanel from 'primevue/overlaypanel';
import Chip from 'primevue/chip';
import OrderOverlayPanel from './OrderOverlayPanel.vue';

export default {
  components: {
    Column,
    DataTable,
    Filter,
    Button,
    CreateForm,
    OverlayPanel,
    Chip,
    OrderOverlayPanel,
  },

  data() {
    return {
      items: [],
      categories: [],
      orderModal: false,
      options: [
        { name: 'Agent', value: 'created_by' },
        { name: 'Status', value: 'status' },
      ],
      paymentMethods: {
        full: 'Pay In Full',
        lease: 'Lease',
      },
      filter: [],
      errors: {},
      order: {},
      error: null,
      activeOrder: null,
      activeOrderStatus: null,
      loading: true,
      total: 0,
      first: 1,
      last: 0,
      currentPage: 0,
      itemsPerPage: 1000,
      status_chips: {
        'ready-for-inventory-check': {
          label: 'Ready for Inventory Check',
          color: 'var(--color-primary)',
        },
        'ready-for-order': {
          label: 'Ready for Order',
          color: 'var(--color-primary)',
        },
        ordered: {
          label: 'Ordered',
          color: 'var(--color-primary)',
        },
        'ready-for-install': {
          label: 'Ready for Install',
          color: 'var(--color-primary)',
        },
        installed: {
          label: 'Installed',
          color: 'var(--color-primary)',
        },
        draft: {
          label: 'Draft',
          color: 'var(--color-primary)',
        },
      },
    };
  },

  computed: {
    currentUserRole() {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user.role) ||
        null
      );
    },

    is_admin() {
      return this.currentUserRole === 'admin';
    },
  },

  created() {
    this.getItems();
  },

  methods: {
    formatPrice(value) {
      return Helper.formatPrice(value);
    },
    showOrderActions(orderId, status, e) {
      this.activeOrder = orderId;
      this.activeOrderStatus = status;
      this.$refs.orderActions.toggle(e);
    },
    changeFilter(event) {
      this.filter = [];
      if (event.name && event.option && event.option.length >= 3) {
        this.filter.name = event.option;
        this.filter.value = event.name;

        this.getItems();
      }
    },
    applyFilter() {
      this.getItems();
    },
    clearFilter() {
      this.filter = [];
      this.getItems();
    },
    onPage(event) {
      this.currentPage = event.page;
      this.getItems();
    },
    showOrder() {
      this.order = { id: this.activeOrder };
      this.orderModal = true;
      this.$refs.orderActions.hide();
      this.activeOrder = null;
      this.activeOrderStatus = null;
    },
    goQuote() {
      window.location = '/order/' + this.activeOrder + '/quote';
    },

    async getItems() {
      this.loading = true;

      const data = await OrdersService.getAll(
        this.itemsPerPage,
        this.currentPage + 1,
        this.filter
      );
      // sort by created_at
      this.items = data.items ?? [];
      this.items.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.total = data.total;
      this.error = data.error ?? null;
      this.loading = false;
    },

    async setOrderStatus(status_string) {
      this.loading = true;
      const data = await OrdersService.setStatus(
        this.activeOrder,
        status_string
      );
      if (data.status) {
        this.$refs.orderActions.hide();
        this.activeOrder = null;
        this.activeOrderStatus = null;
        await this.getItems();
      }
      this.error = data.error ?? null;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.pp-table.p-component {
  font-size: 14px;
}
.pp-chip {
  min-width: 128px;
  display: inline-block !important;
  text-align: center;
  font-size: 13px !important;
  &.p-chip .p-chip-text {
    line-height: 1.05em !important;
  }
}
.pp-chip-draft {
  background-color: #e0e0e0 !important;
  color: #424242 !important;
}
.pp-chip-ready-for-inventory-check {
  // purple
  background-color: #9c27b0 !important;
  color: #fff !important;
}
.pp-chip-ready-for-order {
  // yellow
  background-color: #ffeb3b !important;
  color: #424242 !important;
}
.pp-chip-ordered {
  // teal
  background-color: #009688 !important;
  color: #ffffff !important;
}
.pp-chip-ready-for-install {
  // blue
  background-color: #2196f3 !important;
  color: #fff !important;
}
.pp-chip-installed {
  // green
  background-color: #4caf50 !important;
  color: #fff !important;
}
</style>
