<template>
  <div class="grid m-0 flex flex-1">
    <small v-if="error" class="p-error">{{ error }}</small>
    <div class="col-12 lg:px-2">
      <div class="p-field mb-3 flex flex-column">
        <label for="name" class="mb-2">Product name</label>
        <InputText
          id="name"
          type="text"
          v-model="data.name"
          getFormatter=""
          :disabled="isLoading"
          :class="{ 'p-invalid': errors.name }"
          aria-describedby="name_help"
        />
        <small id="name_help" v-if="errors.name" class="p-error">{{
          errors.name[0]
        }}</small>
      </div>

      <div class="p-field mb-2 flex flex-column">
        <label class="mb-2">Category</label>
        <Dropdown
          v-model="data.category_id"
          :options="categories"
          optionLabel="name"
          optionValue="id"
          inputId="application-filters"
          placeholder="Select category"
          :class="{ 'p-invalid': errors.category_id }"
          class="lg:mr-4 mb-3 lg:mb-0 w-full"
          id="category_id_field"
        />
        <small id="type_help" v-if="errors.category_id" class="p-error">{{
          errors.category_id[0]
        }}</small>
      </div>

      <div class="flex flex-column sm:flex-row pp-sub-row-correction mb-2">
        <div class="p-field sm:col-4 col-12">
          <label for="sku" class="mb-2">SKU</label>
          <div class="flex mt-2">
            <InputText
              id="sku"
              v-model="data.sku"
              :disabled="isLoading"
              :class="{ 'p-invalid': errors.sku }"
              aria-describedby="sku_help"
            />
          </div>
          <small id="sku_help" v-if="errors.sku" class="p-error">{{
            errors.sku[0]
          }}</small>
        </div>
        <div class="p-field sm:col-4 col-12">
          <label for="price_pif" class="mb-2">Standard Price</label>
          <div class="flex mt-2">
            <span class="p-inputgroup-addon pp-price-input-addon"
              ><i class="pi pi-dollar"
            /></span>
            <InputNumber
              id="price_pif"
              mode="currency"
              currency="USD"
              v-model.number="data.price_pif"
              :disabled="isLoading"
              :class="{ 'p-invalid': errors.price_pif }"
              aria-describedby="name_help"
              class="pp-price-input"
            />
          </div>
          <small id="price_pif_help" v-if="errors.price_pif" class="p-error">{{
            errors.price_pif[0]
          }}</small>
        </div>
        <div class="p-field sm:col-4 col-12">
          <label for="price_lease" class="mb-2">Lease Price</label>
          <div class="flex mt-2">
            <span class="p-inputgroup-addon pp-price-input-addon"
              ><i class="pi pi-dollar"
            /></span>
            <InputNumber
              id="price_lease"
              mode="currency"
              currency="USD"
              v-model.number="data.price_lease"
              :disabled="isLoading"
              :class="{ 'p-invalid': errors.price_lease }"
              aria-describedby="name_help"
              class="pp-price-input"
            />
          </div>
          <small
            id="price_lease_help"
            v-if="errors.price_lease"
            class="p-error"
            >{{ errors.price_lease[0] }}</small
          >
        </div>
      </div>

      <div class="p-field mb-2 flex flex-column">
        <label for="description" class="mb-2">Product Description</label>
        <Textarea
          id="description"
          v-model="data.description"
          getFormatter=""
          :disabled="isLoading"
          :class="{ 'p-invalid': errors.description }"
          aria-describedby="description_help"
        />
        <small
          id="description_help"
          v-if="errors.description"
          class="p-error"
          >{{ errors.description[0] }}</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';

export default {
  name: 'ProductForm',

  components: {
    InputText,
    Textarea,
    Dropdown,
    InputNumber,
  },

  emits: ['update:product'],

  props: {
    isLoading: Boolean,
    errors: Object,
    error: String,
    product: Object,
    categories: Object,
  },

  data() {
    return {
      data: this.product,
    };
  },

  mounted() {
    if (this.product.category) {
      this.data.category_id =
        this.categories.find(
          (category) => category.name === this.product.category
        ).id || null;
    }
  },

  updated() {
    this.data = this.product;
  },

  watch: {
    data: {
      handler() {
        this.$emit('update:product', this.data);
      },
      deep: true,
    },
  },
};
</script>

<style>
#price_pif,
#price_lease {
  width: 100%;
}
#sku.p-inputtext,
#price_pif > .p-inputtext,
#price_lease > .p-inputtext {
  min-width: 90px;
  width: 100%;
}

.pp-price-input .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-inputgroup-addon:first-child.pp-price-input-addon {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pp-sub-row-correction {
  margin-left: -8px;
  margin-right: -8px;
}
</style>
