<template>
  <page title="Users">
    <Users />
  </page>
</template>

<script>
import Page from '@/components/Page';
import Users from '../components/Users.vue';

export default {
  components: {
    Page,
    Users,
  },
};
</script>
