<template>
  <TabView>
    <TabPanel header="Products">
      <div class="mb-4">
        <div id="applications-list-toolbar">
          <div
            class="flex flex-column-reverse lg:flex-row justify-content-between align-items-start md:align-items-center"
          >
            <Filter
              :options="options"
              @clearFilter="clearFilter()"
              @applyFilter="applyFilter()"
              @changeFilter="changeFilter($event)"
            />
            <div class="flex sm:w-full md:w-auto mb-3 lg:mb-0 w-full md:w-auto">
              <Button
                @click="productModal = true"
                class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center"
              >
                Create Product
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div id="applications-list-table">
        <DataTable
          :value="items"
          responsiveLayout="scroll"
          :lazy="true"
          :paginator="true"
          :rows="itemsPerPage"
          :totalRecords="total"
          :loading="loading"
          @page="onPage($event)"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <template #empty>
            <div class="p-3">No Products found</div>
          </template>
          <Column field="name" header="Product Name" style="min-width: 140px">
            <template #body="slotProps">
              <div class="p-3">
                {{ slotProps.data.name }}
              </div>
            </template>
          </Column>
          <Column field="name" header="SKU">
            <template #body="slotProps">
              <div class="p-3">
                {{ slotProps.data.sku }}
              </div>
            </template>
          </Column>
          <Column field="name" header="Description" style="min-width: 150px">
            <template #body="slotProps">
              <div class="p-3">
                {{ slotProps.data.description }}
              </div>
            </template>
          </Column>
          <Column field="name" header="Category">
            <template #body="slotProps">
              <div class="p-3">
                {{ slotProps.data.category }}
              </div>
            </template>
          </Column>
          <Column field="price_pif" header="Standard Price">
            <template #body="slotProps">
              <div class="p-3">
                {{ formatPrice(slotProps.data.price_pif) }}
              </div>
            </template>
          </Column>
          <Column field="price_lease" header="Lease Price">
            <template #body="slotProps">
              <div class="p-3">
                {{ formatPrice(slotProps.data.price_lease) }}
              </div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <i
                @click="showProduct({ ...slotProps.data })"
                class="pi pi-fw pi-chevron-right table-icon cursor-pointer"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
    <TabPanel header="Categories">
      <div class="mb-4">
        <div>
          <div
            class="flex flex-column-reverse lg:flex-row justify-content-end align-items-end md:align-items-center"
          >
            <div
              class="flex sm:w-full md:w-auto mb-3 lg:mb-0 lg:align-content-end w-full md:w-auto"
            >
              <Button
                @click="categoryModal = true"
                class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center"
              >
                Create Category
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <DataTable
          :value="categories"
          responsiveLayout="scroll"
          :lazy="true"
          :loading="loading"
        >
          <template #empty>
            <div class="p-3">No Categories found</div>
          </template>
          <Column field="name" header="Category Name">
            <template #body="slotProps">
              <div class="p-3">
                {{ slotProps.data.name }}
              </div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <i
                @click="showCategory({ ...slotProps.data })"
                class="pi pi-fw pi-chevron-right table-icon cursor-pointer"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </TabPanel>
  </TabView>
  <Dialog
    v-model:visible="productModal"
    @hide="productData = {}"
    :breakpoints="{ '636px': '95vw' }"
    :style="{ width: '600px' }"
  >
    <template #header>
      <span class="p-dialog-title">New Product</span>
    </template>

    <product-form
      :categories="categories"
      :is-loading="loading"
      v-model:product="productData"
      :error="error"
      :errors="errors"
    />

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          :disabled="loading"
          @click="
            productData = {};
            productModal = false;
          "
          label="Cancel"
          class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"
        />
        <Button
          :disabled="loading"
          @click="createProduct()"
          label="Save"
          class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="categoryModal"
    @hide="categoryData = {}"
    :breakpoints="{ '636px': '95vw' }"
    :style="{ width: '600px' }"
  >
    <template #header>
      <span class="p-dialog-title">New Category</span>
    </template>
    <create-category-form
      :is-loading="loading"
      v-model:category="categoryData"
      :error="error"
      :errors="errors"
    />

    <template #footer>
      <div class="flex justify-content-end">
        <Button
          :disabled="loading"
          @click="categoryModal = false"
          label="Cancel"
          class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"
        />
        <Button
          :disabled="loading"
          @click="createCategory()"
          label="Save"
          class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ProductsService from '@/services/api-calls/products.service';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Filter from '../Filter';
import ProductForm from './ProductForm';
import CreateCategoryForm from './CreateCategoryForm';
import Dialog from 'primevue/dialog';
import Helper from '@/services/helper';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default {
  components: {
    Column,
    DataTable,
    Button,
    Dialog,
    ProductForm,
    CreateCategoryForm,
    TabPanel,
    TabView,
    Filter,
  },

  created() {
    this.getItems();
    this.getCategories();
  },

  data() {
    return {
      productModal: false,
      categoryModal: false,
      productData: {},
      categoryData: {},
      items: [],
      categories: [],
      options: [
        { name: 'Name', value: 'name' },
        { name: 'SKU', value: 'sku' },
      ],
      filter: [],
      errors: {},
      error: null,
      loading: true,
      total: 0,
      first: 1,
      last: 0,
      currentPage: 0,
      itemsPerPage: 1000,
    };
  },

  methods: {
    formatPrice(value) {
      return Helper.formatPrice(value);
    },

    showProduct(data) {
      data.price_pif /= 100;
      data.price_lease /= 100;
      this.productData = data;
      this.productModal = true;
      this.errors = [];
    },

    showCategory(data) {
      this.categoryData = data;
      this.categoryModal = true;
    },

    async createProduct() {
      console.log('create product', this.productData);
      this.loading = true;
      this.errors = [];
      let productData = { ...this.productData };
      const errors = {};
      let has_errors = false;

      if (!productData.description) delete productData.description;

      if (!productData.name) {
        errors.name = ['Product Name is required'];
        has_errors = true;
      }

      if (!productData.sku) {
        errors.sku = ['SKU is required'];
        has_errors = true;
      }

      if (!productData.category_id) {
        errors.category_id = ['Category is required'];
        has_errors = true;
      }

      if (!productData.price_pif && productData.price_pif !== 0) {
        errors.price_pif = ['Price is required'];
        has_errors = true;
      }

      if (!productData.price_lease && productData.price_lease !== 0) {
        errors.price_lease = ['Lease Price is required'];
        has_errors = true;
      }

      if (has_errors) {
        this.errors = errors;
        this.loading = false;
        return;
      }

      const data = productData.id
        ? await ProductsService.updateProduct(productData.id, productData)
        : await ProductsService.createProduct(productData);

      this.errors = data.errors ?? [];
      this.error = data.error ?? null;
      if (data.data.id) {
        this.productData = {};
        this.productModal = false;
        await this.getItems();
      }
      this.loading = false;
    },

    async createCategory() {
      this.loading = true;

      const data = this.categoryData.id
        ? await ProductsService.updateCategory(this.categoryData.id, {
            ...this.categoryData,
          })
        : await ProductsService.createCategory({ ...this.categoryData });

      this.errors = data.errors ?? [];
      this.error = data.error ?? null;
      if (data.id) {
        if (this.categoryData.id) {
          await this.getItems();
        }
        this.categoryData = {};
        this.categoryModal = false;
        await this.getCategories();
      }
      this.loading = false;
    },

    changeFilter(event) {
      this.filter = [];
      if (event.name && event.option && event.option.length >= 3) {
        this.filter.name = event.option;
        this.filter.value = event.name;

        this.getItems();
      }
    },

    applyFilter() {
      this.getItems();
    },

    clearFilter() {
      this.filter = [];
      this.getItems();
    },

    onPage(event) {
      this.currentPage = event.page;
      this.getItems();
    },

    async getCategories() {
      this.loading = true;
      const data = await ProductsService.getAllCategories();
      this.categories = data.items ?? [];
      this.loading = false;
    },

    async getItems() {
      this.loading = true;

      const data = await ProductsService.getAll(
        this.itemsPerPage,
        this.currentPage + 1,
        this.filter
      );
      this.items = data.data ?? [];
      this.total = data.total;
      this.error = data.error ?? null;
      this.loading = false;
    },
  },
};
</script>
