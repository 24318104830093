<template>
  <div>
    <div id="applications-list-toolbar" class="mb-4">
      <div
        class="flex flex-column-reverse lg:flex-row justify-content-between align-items-start md:align-items-center"
      >
        <div></div>
        <div class="flex sm:w-full md:w-auto mb-3 lg:mb-0 w-full md:w-auto">
          <Button
            @click="inviteModal = true"
            class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center"
          >
            Invite New User
          </Button>
        </div>
      </div>
    </div>
    <div id="applications-list-table">
      <DataTable :value="users" :loading="loading">
        <Column field="email" header="Email">
          <template #body="slotProps">
            <div class="p-3">
              {{ slotProps.data.email }}
            </div>
          </template>
        </Column>
        <Column field="name" header="Name">
          <template #body="slotProps">
            <div class="p-3">
              {{ slotProps.data.name }}
            </div>
          </template>
        </Column>
        <Column field="confirmed" header="Confirmed">
          <template #body="slotProps">
            <div class="p-3">
              <i
                v-if="slotProps.data.confirmed"
                class="p-success pi pi-check"
              ></i>
              <i v-else class="p-error pi pi-times"></i>
            </div>
          </template>
        </Column>
        <Column field="last_login" header="Last login">
          <template #body="slotProps">
            <div class="p-3">
              {{ slotProps.data.last_login }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="inviteModal"
      :breakpoints="{ '960px': '75vw', '769px': '95vw' }"
      :style="{ width: '60vw' }"
    >
      <template #header>
        <span class="p-dialog-title">Invite a Sales User</span>
      </template>

      <InviteUser @invited="invitedUser"></InviteUser>

      <template #footer>
        <div class="flex justify-content-end">
          <Button
            :disabled="loading"
            @click="inviteModal = false"
            label="Cancel"
            class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"
          />
          <!-- <Button
            :disabled="loading"
            label="Invite"
            class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
          /> -->
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import UsersService from '@/services/api-calls/users.service';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InviteUser from '@/components/InviteUser.vue';

export default {
  components: {
    Column,
    DataTable,
    Button,
    Dialog,
    InviteUser,
  },
  created() {
    this.getItems();
  },
  data() {
    return {
      loading: false,
      users: [],
      inviteModal: false,
    };
  },
  methods: {
    async getItems() {
      this.loading = true;
      const data = await UsersService.getUsers();
      this.users = data.users ?? [];
      this.loading = false;
    },

    invitedUser() {
      this.inviteModal = false;
      this.getItems();
    },
  },
};
</script>
