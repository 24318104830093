import ApiService from '../api.service';

class InviteUser {
  invite(email, name, last_name) {
    let data = {
      email: email,
      target: process.env.VUE_APP_URL + 'register/',
      name: name,
      last_name: last_name,
    };

    Object.keys(data).forEach(
      (k) => (data[k] == null || data[k] === '') && delete data[k]
    );

    return ApiService.request('post', 'admin/user/invite', data);
  }

  inviteParsePosUser(email, merchantNumber) {
    return ApiService.request('post', 'parse-pos/invite/' + merchantNumber, {
      email: email,
      target: process.env.VUE_APP_URL + 'login/customer/',
    });
  }
}

export default new InviteUser();
