import { auth } from './auth.module';
import { settings } from './settings.module';
import { merchantApplication } from './merchant-application.module';
import { logrocketStore } from './logrocket.module';
import Vuex from 'vuex';

const store = new Vuex.Store({
  modules: {
    auth,
    settings,
    merchantApplication,
    logrocket: logrocketStore,
  },
});

export default store;
