import LogRocket from 'logrocket';
LogRocket.init('rd7wg9/employee-portal-2ggj1');

export const logrocketStore = {
  namespaced: true,
  state: {
    logrocket_identified: false,
  },
  actions: {
    identifyLogRocket(store) {
      if (store.state.logrocket_identified) {
        console.debug('LogRocket already identified');
        return;
      }
      const username = localStorage.getItem('username');
      if (username) {
        console.debug('User logged in, identifying with LogRocket', username);
        LogRocket.identify(username);
        store.commit('setLogRocketIdentified');
      }
    },
  },
  mutations: {
    setLogRocketIdentified(state) {
      state.logrocket_identified = true;
    },
  },
}