<template>
  <form id="register-form" @submit="handleRegister">
    <div id="subheader-text">Welcome to Parsepay</div>
    <div id="header-text" class="mb-4">Sign up for the<br/> Hardware Orders Portal</div>
    <Message severity="error" v-if="error">
      {{ error }}
    </Message>
    <div class="p-field mb-3">
      <InputText id="username" type="text" v-model="username" placeholder="First name"/>
      <small v-if="errors && errors['username']" id="username-help" class="p-error">{{ errors['username'] }}</small>
    </div>
    <div class="p-field mb-3">
      <InputText id="lastname" type="text" v-model="lastname" placeholder="Last name"/>
      <small v-if="errors && errors['lastname']" id="lastname-help" class="p-error">{{ errors['lastname'] }}</small>
    </div>
    <div class="p-field mb-3">
      <InputText id="password" type="password" v-model="password" placeholder="Password"/>
      <small v-if="errors && errors['password']" id="password-help" class="p-error">{{ errors['password'] }}</small>
    </div>
    <div class="p-field mb-4">
      <InputText id="password2" type="password" v-model="password2" placeholder="Confirm password"/>
      <small v-if="errors && errors['password2']" id="password2-help" class="p-error">{{ errors['password2'] }}</small>
    </div>
    <Button :disabled="loading || !canSend" type="submit" class="mb-3 btn-blue" label="Create Account"/>
    <div class="helper-text-1">
      <router-link to="/login" class="no-underline">I already have an account</router-link>
    </div>
  </form>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import AuthService from '@/services/auth.service';

export default {
  name: 'Login',
  components: {
    InputText,
    Button,
    Message,
  },
  data() {
    return {
      canSend: false,
      loading: false,
      error: '',
      errors: [],
      username: '',
      lastname: '',
      password: '',
      password2: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if(this.loggedIn) {
      this.$router.push('/');
    } else {
      this.loading = true;
      AuthService.start(this.$route.params.token).then(
          (result) => {
            if(result && result.success) {
              this.canSend = true;
              this.loading = false;
              if (result.user_data) {
                  this.username = result.user_data.name ?? ''
                  this.lastname = result.user_data.last_name ?? ''
              }
            }
          },
          error => {
            this.loading = false;
            this.error =
                (error.response &&
                    error.response.data &&
                    error.response.data.message
                ) || 'Invalid register link.';
          },
      );
    }
  },
  methods: {
    handleRegister(e) {
      e.preventDefault();
      this.loading = true;
      this.$store.dispatch('auth/register', {
        token: this.$route.params.token,
        username: this.username,
        lastname: this.lastname,
        password: this.password,
        password2: this.password2,
      }).then(
          () => {
            this.$router.push('/');
          },
          (error) => {
            this.loading = false;
            this.error =
                (error.response &&
                    error.response.data &&
                    error.response.data.message
                ) || 'Invalid register link.';

            this.errors =
                (error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) || [];
          },
      );
    },
  },
};
</script>

