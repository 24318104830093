import axios from 'axios';
import AuthService from './auth.service';
import authHeader from './auth-header';

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (401 === error.response.status || 403 === error.response.status) {
      AuthService.logout();
    }
    return {
      error:
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        'Server error.',
      errors:
        (error.response && error.response.data && error.response.data.errors) ||
        [],
    };
  }
);

class ApiService {
  async request(method, url, data) {
    let authHeaders = await authHeader();

    if (authHeaders) {
      const options = {
        method: method,
        url:
          process.env.VUE_APP_BACKEND_URL +
          process.env.VUE_APP_BACKEND_API_URL +
          url,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...authHeaders,
        },
      };
      if ('get' === method) {
        options.params = data;
      }

      return axios(options);
    }
  }

  async downloadRequest(method, url, data, type, filename) {
    let authHeaders = await authHeader();

    if (authHeaders) {
      const options = {
        method: method,
        url:
          process.env.VUE_APP_BACKEND_URL +
          process.env.VUE_APP_BACKEND_API_URL +
          url,
        data: data,
        responseType: 'blob',
        headers: {
          ...authHeaders,
        },
      };
      if ('get' === method) {
        options.params = data;
      }

      const response = await axios(options);

      const blob = new Blob([response], { type: type });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  }
  async uploadRequest(url, data) {
    let authHeaders = await authHeader();
    if (authHeaders) {
      const headers = {
        'Content-Type': 'multipart/form-data',
        ...authHeaders,
      };

      return axios.post(
        process.env.VUE_APP_BACKEND_URL +
          process.env.VUE_APP_BACKEND_API_URL +
          url,
        data,
        { headers: headers }
      );
    }
  }
}

export default new ApiService();
