<template>
  <page title="Hardware Orders">
    <Orders />
  </page>
</template>

<script>
import Page from '@/components/Page';
import Orders from '../components/Orders/Orders';

export default {
  components: {
    Page,
    Orders,
  },
};
</script>
