<template>
  <div class="full-screen-modal-wrapper">
    <div class="modal-backdrop">
      <div class="modal full-screen-modal">
        <small v-if="error" class="p-error">{{ error }}</small>
        <header class="modal-header modal-header-with-content">
          <ProgressBar
            v-if="isLoading"
            style="
              position: absolute;
              left: 0px;
              top: 2px;
              height: 4px;
              width: 100%;
            "
            mode="indeterminate"
          />
          <Button
            type="button"
            class="p-button-secondary modal-close-btn btn-no-border"
            @click="close"
            ><i class="pi pi-times"
          /></Button>
          <h2 class="full-screen-modal-title m-0">Hardware Order Form</h2>
          <div class="hidden lg:block">
            <Button
              v-if="current_order_status === 'draft' || !current_order_status"
              :disabled="isLoading"
              @click="save(false, 'draft')"
              class="p-button-shaded mr-2"
              label="Save Draft"
            />
            <Button
              v-if="current_order_status === 'draft' || !current_order_status"
              :disabled="isLoading"
              @click="save(true, 'ready-for-inventory-check')"
              label="Submit Order"
            />

            <Button
              v-if="current_order_status && current_order_status !== 'draft'"
              :disabled="isLoading"
              @click="save(false, current_order_status)"
              class="p-button-shaded mr-2"
              label="Save"
            />
            <Button
              v-if="current_order_status && current_order_status !== 'draft'"
              :disabled="isLoading"
              @click="save(true, current_order_status)"
              class="p-button"
              label="Save & Close"
            />
          </div>
        </header>
        <section class="modal-body">
          <div id="new-merchant-order-content">
            <div class="grid m-0 flex flex-1">
              <div class="col-12 px-0">
                <h3 class="mb-3">Merchant Information</h3>
                <div class="grid mb-3">
                  <div class="p-field mb-2 col-12 lg:col-6 flex flex-column">
                    <label for="business_name" class="mb-2"
                      >Business name</label
                    >
                    <InputText
                      id="business_name"
                      type="text"
                      v-model="form_data.business_name"
                      getFormatter=""
                      :disabled="isLoading"
                      :class="{ 'p-invalid': errors.business_name }"
                      aria-describedby="business_name"
                    />
                    <small
                      id="business_name_help"
                      v-if="errors.business_name"
                      class="p-error"
                      >{{ errors.business_name[0] }}</small
                    >
                  </div>
                </div>
                <div class="grid mb-3">
                  <div class="p-field mb-2 col-12 md:col-6 lg:col-3 flex flex-column">
                    <label for="merchants_first_name" class="mb-2"
                      >Merchants First Name</label
                    >
                    <InputText
                      id="merchants_first_name"
                      type="text"
                      v-model="form_data.merchants_first_name"
                      getFormatter=""
                      :disabled="isLoading"
                      :class="{ 'p-invalid': errors.merchants_first_name }"
                      aria-describedby="merchants_first_name_help"
                    />
                    <small
                      id="merchants_first_name_help"
                      v-if="errors.merchants_first_name"
                      class="p-error"
                      >{{ errors.merchants_first_name[0] }}</small
                    >
                  </div>
                  <div class="p-field mb-2 col-12 md:col-6 lg:col-3 flex flex-column">
                    <label for="merchants_last_name" class="mb-2"
                      >Merchants Last Name</label
                    >
                    <InputText
                      id="merchants_last_name"
                      type="text"
                      v-model="form_data.merchants_last_name"
                      getFormatter=""
                      :disabled="isLoading"
                      :class="{ 'p-invalid': errors.merchants_last_name }"
                      aria-describedby="merchants_last_name_help"
                    />
                    <small
                      id="merchants_last_name_help"
                      v-if="errors.merchants_last_name"
                      class="p-error"
                      >{{ errors.merchants_last_name[0] }}</small
                    >
                  </div>
                  <div class="p-field mb-2 col-12 md:col-6 lg:col-3 flex flex-column">
                    <label for="merchants_email" class="mb-2"
                      >Merchants Email</label
                    >
                    <InputText
                      id="merchants_email"
                      type="text"
                      v-model="form_data.merchants_email"
                      getFormatter=""
                      :disabled="isLoading"
                      :class="{ 'p-invalid': errors.merchants_email }"
                      aria-describedby="merchants_email_help"
                    />
                    <small
                      id="merchants_email_help"
                      v-if="errors.merchants_email"
                      class="p-error"
                      >{{ errors.merchants_email[0] }}</small
                    >
                  </div>
                  <div class="p-field mb-2 col-12 md:col-6 lg:col-3 flex flex-column">
                    <label for="merchants_phone" class="mb-2"
                      >Merchants Phone</label
                    >
                    <InputMask
                      id="merchants_phone"
                      placeholder="(___) ___-____"
                      type="text"
                      mask="(999) 999-9999"
                      v-model="form_data.merchants_phone"
                      :disabled="isLoading"
                      :class="{ 'p-invalid': errors.merchants_phone }"
                      aria-describedby="merchants_phone_help"
                    />
                    <small
                      id="merchants_phone_help"
                      v-if="errors.merchants_phone"
                      class="p-error"
                      >{{ errors.merchants_phone[0] }}</small
                    >
                  </div>
                  <div class="col-12 lg:col-6 px-0 lg:px-2">
                    <div class="p-field mb-2 flex flex-column">
                      <label for="street" class="mb-2">Street Address</label>
                      <InputText
                        id="street"
                        type="text"
                        v-model="form_data.street"
                        :disabled="isLoading"
                        :class="{ 'p-invalid': errors.street }"
                        aria-describedby="street_help"
                      />
                      <small
                        id="street_help"
                        v-if="errors.street"
                        class="p-error"
                        >{{ errors.street[0] }}</small
                      >
                    </div>
                  </div>
                  <div class="col-12 lg:col-6">
                    <div class="grid">
                      <div class="col-12 lg:col-6 px-0 lg:px-2">
                        <div class="p-field mb-2 flex flex-column">
                          <label for="city" class="mb-2">City</label>
                          <InputText
                            id="city"
                            type="text"
                            v-model="form_data.city"
                            :disabled="isLoading"
                            :class="{ 'p-invalid': errors.city }"
                            aria-describedby="city_help"
                          />
                          <small
                            id="city_help"
                            v-if="errors.city"
                            class="p-error"
                            >{{ errors.city[0] }}</small
                          >
                        </div>
                      </div>
                      <div class="col-12 lg:col-3 px-0 lg:px-2">
                        <div class="p-field mb-2 flex flex-column">
                          <label for="state" class="mb-2">State</label>
                          <Dropdown
                            v-model="form_data.state"
                            :options="states ?? []"
                            optionLabel="name"
                            optionValue="value"
                            id="state"
                            placeholder="Select"
                            :disabled="isLoading"
                            :class="{ 'p-invalid': errors.state }"
                            aria-describedby="state_help"
                          />
                        </div>
                        <small
                          id="state_help"
                          v-if="errors.state"
                          class="p-error"
                          >{{ errors.state[0] }}</small
                        >
                      </div>
                      <div class="col-12 lg:col-3 px-0 lg:px-2">
                        <div class="p-field mb-2 flex flex-column">
                          <label for="zip" class="mb-2">Zip</label>
                          <InputText
                            id="zip"
                            type="text"
                            v-model="form_data.zip"
                            :disabled="isLoading"
                            :class="{ 'p-invalid': errors.zip }"
                            aria-describedby="zip_help"
                          />
                          <small
                            id="zip_help"
                            v-if="errors.zip"
                            class="p-error"
                            >{{ errors.zip[0] }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="flex flex-column mb-3 px-0 lg:px-2">
                      <p class="mt-0">
                        Is the shipping address the same as the business
                        address?
                      </p>
                      <div class="flex">
                        <div class="p-field-radiobutton mr-4">
                          <RadioButton
                            id="is_same_shipping1"
                            :value="true"
                            v-model="form_data.same_shipping"
                          />
                          <label for="is_same_shipping1">Yes</label>
                        </div>
                        <div class="p-field-radiobutton mr-4">
                          <RadioButton
                            id="is_same_shipping2"
                            :value="false"
                            v-model="form_data.same_shipping"
                          />
                          <label for="is_same_shipping2">No</label>
                        </div>
                        <small v-if="errors.same_shipping" class="p-error">{{
                          errors.same_shipping[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div v-if="!form_data.same_shipping" class="col-12 lg:col-6">
                    <div class="p-field mb-2 flex flex-column">
                      <label for="shipping_street" class="mb-2"
                        >Shipping Address</label
                      >
                      <InputText
                        id="shipping_street"
                        type="text"
                        v-model="form_data.shipping_street"
                        :disabled="isLoading"
                        :class="{ 'p-invalid': errors.shipping_street }"
                        aria-describedby="shipping_street_help"
                      />
                      <small
                        id="shipping_street_help"
                        v-if="errors.shipping_street"
                        class="p-error"
                        >{{ errors.shipping_street[0] }}</small
                      >
                    </div>
                  </div>
                  <div v-if="!form_data.same_shipping" class="col-12 lg:col-6">
                    <div class="grid">
                      <div class="col-12 lg:col-6">
                        <div class="p-field mb-2 flex flex-column">
                          <label for="city" class="mb-2">City</label>
                          <InputText
                            id="city_mailing"
                            type="text"
                            v-model="form_data.shipping_city"
                            :disabled="isLoading"
                            :class="{ 'p-invalid': errors.shipping_city }"
                            aria-describedby="shipping_city_help"
                          />
                          <small
                            id="shipping_city_help"
                            v-if="errors.shipping_city"
                            class="p-error"
                            >{{ errors.shipping_city[0] }}</small
                          >
                        </div>
                      </div>
                      <div class="col-12 lg:col-3">
                        <div class="p-field mb-2 flex flex-column">
                          <label for="state" class="mb-2">State</label>
                          <Dropdown
                            v-model="form_data.shipping_state"
                            :options="states ?? []"
                            optionLabel="name"
                            optionValue="value"
                            id="shipping_state"
                            placeholder="Select"
                            :disabled="isLoading"
                            :class="{ 'p-invalid': errors.shipping_state }"
                            aria-describedby="shipping_state_help"
                          />
                          <small
                            id="shipping_state_help"
                            v-if="errors.shipping_state"
                            class="p-error"
                            >{{ errors.shipping_state[0] }}</small
                          >
                        </div>
                      </div>
                      <div class="col-12 lg:col-3">
                        <div class="p-field mb-2 flex flex-column">
                          <label for="zip" class="mb-2">Zip</label>
                          <InputText
                            id="shipping_zip"
                            type="text"
                            v-model="form_data.shipping_zip"
                            :disabled="isLoading"
                            :class="{ 'p-invalid': errors.shipping_zip }"
                            aria-describedby="shipping_zip_help"
                          />
                          <small
                            id="shipping_zip_help"
                            v-if="errors.shipping_zip"
                            class="p-error"
                            >{{ errors.shipping_zip[0] }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid mb-3">
                  <div class="p-field mb-2 col-12 md:col-3 flex flex-column">
                    <label for="delivery_date" class="mb-2"
                      >Desired Delivery Date</label
                    >
                    <InputMask
                      id="delivery_date"
                      mask="99/99/9999"
                      slotChar="mm/dd/yyyy"
                      placeholder="__/__/____"
                      type="text"
                      v-model="form_data.delivery_date"
                      :class="{ 'p-invalid': errors.delivery_date }"
                      aria-describedby="delivery_date_help"
                    />
                    <small
                      id="delivery_date_help"
                      v-if="errors.delivery_date"
                      class="p-error"
                      >{{ errors.delivery_date[0] }}</small
                    >
                  </div>
                  <div class="p-field mb-2 col-12 md:col-3 flex flex-column">
                    <label for="install_date" class="mb-2"
                      >Desired Install Date</label
                    >
                    <InputMask
                      id="install_date"
                      mask="99/99/9999"
                      slotChar="mm/dd/yyyy"
                      placeholder="__/__/____"
                      type="text"
                      v-model="form_data.install_date"
                      :class="{ 'p-invalid': errors.install_date }"
                      aria-describedby="install_date_help"
                    />
                    <small
                      id="install_date_help"
                      v-if="errors.install_date"
                      class="p-error"
                      >{{ errors.install_date[0] }}</small
                    >
                  </div>
                </div>

                <h3 class="mb-3">Payment Options</h3>
                <div class="col-12">
                  <div class="flex flex-column mb-3 px-0 lg:px-2">
                    <p class="mt-0">Method of payment</p>
                    <div class="flex">
                      <div class="p-field-radiobutton mr-4">
                        <RadioButton
                          id="payment_method1"
                          value="full"
                          v-model="form_data.payment_method"
                        />
                        <label for="payment_method1">Pay In Full</label>
                      </div>
                      <div class="p-field-radiobutton mr-4">
                        <RadioButton
                          id="payment_method2"
                          value="lease"
                          v-model="form_data.payment_method"
                        />
                        <label for="payment_method2">Lease</label>
                      </div>
                      <small v-if="errors.payment_method" class="p-error">{{
                        errors.payment_method[0]
                      }}</small>
                    </div>
                  </div>
                </div>

                <div
                  class="mb-6 flex flex-column"
                  v-if="form_data.payment_method !== 'lease'"
                >
                  <h3 class="mb-2">Sales Tax Rate</h3>
                  <div class="mb-2">{{ sales_tax_rate_string }}%</div>
                  <div>
                    <Button
                      v-if="!sales_tax_rate"
                      :disabled="isLoading || loading_sales_tax_rate"
                      @click="getSalesTaxRate"
                      class="mb-2 p-button-lg"
                      label="Get Sales Tax Rate"
                    />
                    <Button
                      v-if="!!sales_tax_rate"
                      :disabled="isLoading || loading_sales_tax_rate"
                      @click="getSalesTaxRate"
                      class="mb-2 p-button-lg"
                      label="Refresh Sales Tax Rate"
                    />
                  </div>
                  <small v-if="!!sales_tax_warning" class="p-error">{{
                    sales_tax_warning
                  }}</small>
                </div>

                <Cart
                  v-if="form_data.items !== undefined"
                  :is-edit="form_data.id !== undefined"
                  v-model:equipment="form_data.items"
                  :payment-method="form_data.payment_method"
                  :sales-tax-rate="sales_tax_rate_computed"
                />
                <small v-if="errors.items" class="p-error">{{
                  errors.items[0]
                }}</small>

                <div class="w-full mb-2 flex flex-column">
                  <label for="note" class="mb-2">Notes</label>
                  <Textarea
                    id="note"
                    :disabled="isLoading"
                    v-model="form_data.note"
                    placeholder="Enter any special notes here."
                    type="text"
                    rows="3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="lg:hidden p-4">
          <Button
            :disabled="isLoading"
            @click="save(false, 'draft')"
            class="col-12 mb-2 p-button-shaded p-button-lg mr-2"
            label="Save"
          />
          <Button
            :disabled="isLoading"
            @click="save(true, 'draft')"
            class="col-12 mb-2 p-button-shaded p-button-lg mr-2"
            label="Save & Close"
          />
          <Button
            :disabled="isLoading"
            @click="save(true, 'ready-for-inventory-check')"
            class="col-12 mb-2 p-button-lg"
            label="Submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Cart from './Cart';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import InputMask from 'primevue/inputmask';
import DictionaryService from '@/services/api-calls/dictionary.service';
import Textarea from 'primevue/textarea';
import FormHelper from '@/services/form.helper';
import ProgressBar from 'primevue/progressbar';
import OrdersService from '@/services/api-calls/orders.service';

export default {
  name: 'FormModal',

  components: {
    Button,
    InputText,
    InputMask,
    Dropdown,
    RadioButton,
    Textarea,
    ProgressBar,
    Cart,
  },

  props: {
    order: Object,
  },

  data() {
    return {
      form_data: this.order.id
        ? this.order
        : { same_shipping: true, items: [], payment_method: 'full' },
      states: [],
      isLoading: false,
      error: null,
      errors: {},
      sales_tax_rate: null,
      sales_tax_warning: '',
      loading_sales_tax_rate: false,
    };
  },

  computed: {
    // parse_pos_user_id_error() {
    //   return this.errors.parse_pos_user_id
    //     ? this.errors.parse_pos_user_id[0]
    //     : '';
    // },
    sales_tax_rate_computed() {
      if (!this.sales_tax_rate) return 0;

      // move the decimal 2 places to the right
      const string_to_array = this.sales_tax_rate.split('');
      for (let i = 0; i < string_to_array.length; i++) {
        if (string_to_array[i] === '.') {
          string_to_array.splice(i, 1);
          string_to_array.splice(i + 2, 0, '.');
          break;
        }
      }

      const rate_from_array = parseFloat(string_to_array.join(''));

      console.log(
        'this.sales_tax_rate',
        this.sales_tax_rate,
        typeof this.sales_tax_rate,
        rate_from_array
      );

      return rate_from_array;
    },

    sales_tax_rate_string() {
      return String(this.sales_tax_rate_computed);
    },

    current_order_status() {
      if (!this.form_data.id) return 'draft';
      return this.form_data.status;
    },
  },

  mounted() {
    this.getStates();
    if (this.order.id) this.get();
  },

  methods: {
    close() {
      this.$emit('close');
    },

    async get() {
      this.loading = true;
      const data = await OrdersService.get(this.order.id);
      this.orderModal = true;
      this.loading = false;

      if (data.id) {
        let order = { ...data };
        let items = [];
        for (let key in order.items) {
          const item_price = this.calcPriceOfItem(
            order.payment_method,
            order.items[key]
          );
          items.push({
            id: order.items[key]['product_id'],
            item_id: order.items[key]['id'],
            category_id: order.items[key]['product']['category_id'],
            category: order.items[key]['product']['category']['name'],
            name: order.items[key]['product']['name'],
            qty: parseInt(order.items[key]['quantity']),
            price: item_price,
            price_pif: parseInt(order.items[key]['price_pif']),
            price_lease: parseInt(order.items[key]['price_lease']),
          });
        }
        if (
          order.shipping_zip === order.zip &&
          order.shipping_state === order.state &&
          order.shipping_city === order.city &&
          order.shipping_street === order.street
        ) {
          order.same_shipping = true;
          delete order.shipping_zip;
          delete order.shipping_state;
          delete order.shipping_city;
          delete order.shipping_street;
        } else {
          order.same_shipping = false;
        }
        order.items = items;
        this.form_data = order;
      }
      this.error = data.error ?? null;
    },

    calcPriceOfItem(payment_method, item) {
      if (payment_method === 'full') {
        return parseInt(item.price_pif);
      } else if (payment_method === 'lease') {
        return parseInt(item.price_lease);
      } else {
        return 0;
      }
    },

    async save(isClose, status) {
      this.isLoading = true;
      const order = { ...this.form_data };
      order.status = status;
      if (!order.note) {
        delete order.note;
      }
      let items = [];
      for (let key in order.items) {
        if (order.items[key].item_id) {
          items.push({
            id: order.items[key].item_id,
            qty: order.items[key].qty,
          });
        } else if (order.items[key].id) {
          items.push({
            product_id: order.items[key].id,
            qty: order.items[key].qty,
          });
        }
      }
      order.items = items;
      const result = order.id
        ? await OrdersService.update(order.id, order)
        : await OrdersService.create(order);

      if (result.id) {
        if (isClose) {
          this.close();
        } else if (!this.form_data.id) {
          this.form_data.id = result.id;
        }
        this.$emit('order');
      }
      this.error = result.error ?? null;
      this.errors = result.errors ?? {};
      this.isLoading = false;
    },

    async getStates() {
      this.isLoading = true;
      const states = await DictionaryService.getStates();
      this.states = states.states
        ? FormHelper.convertOptions(states).states
        : [];
      this.error = states.error ?? null;
      this.isLoading = false;
    },

    async getSalesTaxRate() {
      this.loading_sales_tax_rate = true;
      this.setSalesTaxWarning();

      const address_data = {
        zip: this.form_data.zip ?? '',
        state: this.form_data.state ?? '',
        city: this.form_data.city ?? '',
        street: this.form_data.street ?? '',
      };

      if (this.form_data.same_shipping === false) {
        address_data.zip = this.form_data.shipping_zip ?? '';
        address_data.state = this.form_data.shipping_state ?? '';
        address_data.city = this.form_data.shipping_city ?? '';
        address_data.street = this.form_data.shipping_street ?? '';
      }

      try {
        const sales_tax_rate = await OrdersService.getTaxRate(address_data);
        console.log('sales_tax_rate', sales_tax_rate);
        this.sales_tax_rate = sales_tax_rate.tax_rate;
      } catch (e) {
        this.setSalesTaxWarning(e.message);
      }
      this.loading_sales_tax_rate = false;
    },

    setSalesTaxWarning(message = '') {
      this.sales_tax_warning = message;
    },
  },
};
</script>
