<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      this.$store.dispatch('logrocket/identifyLogRocket');
    },
  },
};
</script>

<style>
body {
  background-color: #f5f7f8;
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

a {
  color: #057cfd;
}
</style>
