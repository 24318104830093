<template>
  <ul
    id="nav-list"
    class="ml-0 mr-0 mt-3 p-0 w-full flex flex-column flex-wrap justify-content-start"
  >
    <router-link
      :to="item.to"
      v-for="item in navItems"
      :key="item.label"
      class="no-underline w-full flex-shrink-1"
      style="height: auto"
    >
      <div
        v-if="
          item.visible !== false &&
          showLocalOnly(item.local_only) &&
          ((!!item.visible && !item.dev_only) ||
            (!!item.dev_only && !!dev_mode))
        "
        class="nav-item"
      >
        <div class="nav-item-sidebar"></div>
        <div class="nav-item-content" :class="item.dev_only ? 'dev-only' : ''">
          <div class="nav-item-icon">
            <i :class="item.icon"></i>
          </div>
          {{ item.label }}
        </div>
      </div>
    </router-link>

    <div
      @click="logOut"
      class="no-underline w-full flex-shrink-1 mt-auto"
      style="height: auto"
    >
      <div class="nav-item">
        <div class="nav-item-sidebar"></div>
        <div class="nav-item-content">
          <div class="nav-item-icon">
            <i class="pi pi-fw pi-sign-out"></i>
          </div>
          Sign Out
        </div>
      </div>
    </div>

    <div class="w-full flex-shrink-1">
      <div class="nav-item-sidebar"></div>
      <div class="nav-item-content flex justify-content-between">
        <div>Dev Mode</div>
        <InputSwitch v-model="button_dev_mode"></InputSwitch>
      </div>
    </div>
  </ul>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';

export default {
  components: { InputSwitch },

  data() {
    return {
      navItems: [],
      routeName: null,
      button_dev_mode: false,
      is_env_development: false,
    };
  },

  computed: {
    currentUserRole() {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user.role) ||
        null
      );
    },

    is_admin() {
      return this.currentUserRole === 'admin';
    },

    dev_mode() {
      return this.$store.getters['settings/devMode'];
    },

    show_local_only() {
      return this.$store.getters['settings/showLocalOnly'];
    },
  },

  mounted() {
    this.button_dev_mode = this.dev_mode;

    this.is_env_development = process.env.NODE_ENV === 'development';
  },

  watch: {
    $route: 'currentRoute',

    dev_mode: function (val) {
      this.button_dev_mode = val;
    },

    button_dev_mode: function (val) {
      this.$store.dispatch('settings/setDevMode', val);
    },
  },

  created() {
    this.navItems = [
      {
        label: 'Orders',
        icon: 'pi pi-fw pi-check-square',
        to: '/',
        visible: true,
        dev_only: false,
        local_only: false,
      },
      {
        label: 'Products',
        icon: 'pi pi-fw pi-tag',
        to: '/products',
        visible: this.is_admin,
        dev_only: false,
        local_only: false,
      },
      {
        label: 'Users',
        icon: 'pi pi-fw pi-users',
        to: '/users',
        visible: this.is_admin,
        dev_only: false,
        local_only: false,
      },

      {
        label: 'Applications',
        icon: 'pi pi-fw pi-check-square',
        to: '/applications',
        visible: this.is_admin,
        dev_only: true,
        local_only: true,
      },
      {
        label: 'Forms',
        icon: 'pi pi-fw pi-tag',
        to: '/forms',
        visible: this.is_admin,
        dev_only: true,
        local_only: true,
      },
      {
        label: 'Documents',
        icon: 'pi pi-fw pi-tag',
        to: '/documents',
        visible: this.is_admin,
        dev_only: true,
        local_only: true,
      },
    ];
  },

  methods: {
    showLocalOnly(is_local_only) {
      if (this.is_env_development) return true;
      return !is_local_only;
    },

    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },

    logOut() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.dev-only {
  color: #b05454;
}
</style>
