<template>
  <div>
    <Dialog
      id="signatures-modal"
      header="Signatures"
      v-model:visible="isOpen"
      @hide="hide()"
      :breakpoints="{ '960px': '75vw', '769px': '95vw' }"
      :style="{ width: '60vw' }"
      :closable="!isLoading"
    >
      <template #header>
        <span class="p-dialog-title">Electronic Signatures</span>
        <div class="flex">
          <ProgressBar
            v-if="isLoading"
            style="
              position: absolute;
              top: 2px;
              left: 0px;
              height: 4px;
              width: 100%;
            "
            mode="indeterminate"
          />
        </div>
      </template>
      <div v-if="terms.length">
        <div class="grid m-0">
          <div class="signatures-title hidden lg:block lg:col-3">
            <div
              :class="{ active: index === openTerm }"
              class="p-text-nowrap p-text-bold pb-4"
              v-for="(term, index) in terms"
              v-bind:key="term.type"
            >
              {{ index + 1 }}. {{ term.title }}
            </div>
          </div>
          <div class="lg:col-9 col-12">
            <div v-if="openTerm > -1">
              <div>
                <h2>{{ terms[openTerm].title }}</h2>
                <div v-html="terms[openTerm].text" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isLoading" class="text-center"><progress-spinner /></div>
      <template #footer>
        <div>
          <small v-if="error" class="p-error">{{ error }}</small>
        </div>
        <Button
          :label="
            this.signed.length < this.terms.length - 1
              ? 'Accept & Continue'
              : 'Accept & Submit signatures'
          "
          :disabled="isLoading || !availableTerms"
          @click="submitOrNext()"
          class="p-button-lg lg:px-12"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import ApplicationService from '@/services/api-calls/application.service';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import FormHelper from '@/services/form.helper';
import Button from 'primevue/button';

export default {
  name: 'Signatures',

  components: {
    Dialog,
    ProgressBar,
    Button,
    ProgressSpinner,
  },

  props: {
    contactId: {
      type: Number,
      required: false,
    },
    merchantNumber: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      isLoading: false,
      error: null,
      signed: [],
      terms: [],
      openTerm: -1,
      availableTerms: false,
    };
  },

  updated() {
    this.isOpen = !!this.contactId;
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.getData();
      }
    },
    openTerm(term) {
      this.hideTerm();
      if (term > -1) {
        this.showTerm();
      }
    },
  },

  methods: {
    showTerm() {
      let el = document
        .getElementById('signatures-modal')
        .getElementsByClassName('p-dialog-content')[0];
      el.addEventListener('scroll', () => {
        if (el.scrollHeight - el.scrollTop - 100 <= el.offsetHeight) {
          this.availableTerms = true;
        }
      });
      setTimeout(function () {
        el.dispatchEvent(new Event('scroll'));
      }, 700);
    },
    hideTerm() {
      this.availableTerms = false;
      let el = document
        .getElementById('signatures-modal')
        .getElementsByClassName('p-dialog-content')[0];
      el.removeEventListener('scroll', () => {
        if (el.scrollHeight - el.scrollTop - 100 <= el.offsetHeight) {
          this.availableTerms = true;
        }
      });
    },
    hide() {
      this.signed = [];
      this.terms = [];
      this.openTerm = -1;
      this.availableTerms = false;
      this.error = null;
      this.$emit('close');
    },
    
    async getData() {
      this.isLoading = true;
      const result = await ApplicationService.getSignatures(
        this.merchantNumber,
        this.contactId
      );
      this.terms = result.terms ?? [];
      if (this.terms) {
        this.openTerm = 0;
      }
      this.error = result.error ? FormHelper.convertGatewayError(result) : null;
      this.isLoading = false;
    },

    submitOrNext() {
      if (this.terms.length && this.openTerm > -1) {
        if (this.signed.length < this.terms.length - 1) {
          this.signed.push(this.terms[this.openTerm].type);
          this.openTerm = this.openTerm + 1;
        } else {
          this.signed.push(this.terms[this.openTerm].type);
          this.submit();
        }
      }
    },
    async submit() {
      this.isLoading = true;
      const result = await ApplicationService.submitSignatures(
        this.merchantNumber,
        this.contactId,
        this.signed
      );
      this.error = result.error ? FormHelper.convertGatewayError(result) : null;
      if (result.errors && result.errors['signature_type_ids']) {
        this.error = result.errors['signature_type_ids'][0];
      }
      this.isLoading = false;
      if (result.application) {
        this.hide();
      }
    },
  },
};
</script>
