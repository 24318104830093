<template>
  <div>
    <p
      v-if="isAdmin && activeOrderStatus === 'ready-for-inventory-check'"
      class="cursor-pointer"
      @click="setOrderStatus('draft')"
    >
      <i class="pi pi-fw pi-step-backward mr-2" />Revert to Draft
    </p>

    <!-- Ready to Order -->
    <p
      v-if="isAdmin && activeOrderStatus === 'ready-for-inventory-check'"
      class="cursor-pointer"
      @click="setOrderStatus('ready-for-order')"
    >
      <i class="pi pi-fw pi-check-circle mr-2" />Mark Ready to Order
    </p>
    <p
      v-if="isAdmin && ['ordered'].includes(activeOrderStatus)"
      class="cursor-pointer"
      @click="setOrderStatus('ready-for-order')"
    >
      <i class="pi pi-fw pi-step-backward mr-2" />Revert to Ready to Order
    </p>

    <!-- Inventory Check -->
    <p
      v-if="
        isAdmin && ['ready-for-order', 'ordered'].includes(activeOrderStatus)
      "
      class="cursor-pointer"
      @click="setOrderStatus('ready-for-inventory-check')"
    >
      <i class="pi pi-fw pi-step-backward mr-2" />Revert to Inventory Check
    </p>

    <!-- Ordered -->
    <p
      v-if="isAdmin && ['ready-for-order'].includes(activeOrderStatus)"
      class="cursor-pointer"
      @click="setOrderStatus('ordered')"
    >
      <i class="pi pi-fw pi-check-circle mr-2" />Mark Ordered
    </p>
    <p
      v-if="['ready-for-install'].includes(activeOrderStatus)"
      class="cursor-pointer"
      @click="setOrderStatus('ordered')"
    >
      <i class="pi pi-fw pi-step-backward mr-2" />Revert to Ordered
    </p>

    <!-- Ready for Install -->
    <p
      v-if="
        isAdmin &&
        ['ready-for-inventory-check', 'ready-for-order', 'ordered'].includes(
          activeOrderStatus
        )
      "
      class="cursor-pointer"
      @click="setOrderStatus('ready-for-install')"
    >
      <i class="pi pi-fw pi-check-circle mr-2" />Mark Ready for Install
    </p>
    <p
      v-if="['installed'].includes(activeOrderStatus)"
      class="cursor-pointer"
      @click="setOrderStatus('ready-for-install')"
    >
      <i class="pi pi-fw pi-step-backward mr-2" />Revert to Ready for Install
    </p>

    <!-- Installed -->
    <p
      v-if="['ready-for-install'].includes(activeOrderStatus)"
      class="cursor-pointer"
      @click="setOrderStatus('installed')"
    >
      <i class="pi pi-fw pi-check-circle mr-2" />Mark Installed
    </p>

    <!-- Edit -->
    <p
      v-if="activeOrderStatus !== 'ordered'"
      class="cursor-pointer"
      @click="showOrder()"
    >
      <i class="pi pi-fw pi-pencil mr-2" />Edit Form
    </p>

    <!-- View Quote -->
    <p @click="goQuote()" class="cursor-pointer">
      <i class="pi pi-fw pi-download mr-2" />View Quote PDF
    </p>
  </div>
</template>

<script>
export default {
  props: {
    activeOrderStatus: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    setOrderStatus(status) {
      this.$emit('setOrderStatus', status);
    },

    showOrder() {
      this.$emit('showOrder');
    },

    goQuote() {
      this.$emit('goQuote');
    },
  },
};
</script>
