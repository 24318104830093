<template>
  <full-screen-modal-with-header
    :init-data="{
      bank_account_name_type: 1,
      bank_account_type: 1,
      is_deposit: false,
      is_fees: false,
      is_chargebacks: false,
    }"
    v-slot:default="props"
    v-if="this.isActive"
    :active="this.isActive"
    :merchant-number="this.application.merchant_number"
    title="Merchant Application"
    prev="profile"
    current="banking"
    next="contacts"
  >
    <div id="new-merchant-app-2">
      <div class="grid m-0 flex flex-1">
        <div class="p-0 col-12 lg:hidden">
          <div
            class="step-description step-description-mobile flex flex-column lg:hidden"
          >
            <div class="flex justify-content-between align-items-center">
              <div class="step flex mb-0">
                <div class="step-number step-number-active">4</div>
                <div class="step-name">Banking</div>
              </div>
              <div class="step-header">Step 4 of 5</div>
            </div>
            <p class="gray-text mb-0">
              We need to link your bank account and obtain an image of a voided
              check.
            </p>
          </div>
        </div>
        <div class="hidden lg:flex lg:col-3 p-0">
          <div class="step-description hidden lg:flex">
            <div>
              <div class="step-header mb-2">Step 4 of 5</div>
              <p class="gray-text mt-2 mb-5">
                We need to link your bank account and obtain an image of a
                voided check.
              </p>
              <FormSteps
                :completed_forms="
                  this.application.completed_steps.completed_forms
                "
                current="banking"
                @open="this.$emit('open', $event)"
              />
            </div>
          </div>
        </div>
        <div class="lg:col-9 col-12 px-0">
          <div class="step-body mt-2 lg:mt-0">
            <div class="step-header mb-5 px-0 lg:px-2">
              Please add your bank account information.
            </div>
            <div class="grid mx-0 mb-4">
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field flex flex-column">
                  <label for="bankName" class="mb-2">Bank Name</label>
                  <InputText
                    id="bankName"
                    data-private
                    type="text"
                    v-model="props.data.bank_name"
                    :disabled="props.params.loading"
                    :class="{ 'p-invalid': props.params.errors.bank_name }"
                    aria-describedby="bank_name_help"
                  />
                  <small
                    id="bank_name_help"
                    v-if="props.params.errors.bank_name"
                    class="p-error"
                    >{{ props.params.errors.bank_name[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="flex flex-column mb-5 px-0 lg:px-2">
              <p class="mt-0">
                What is the name listed on the banking account?
              </p>
              <div class="flex">
                <div class="p-field-radiobutton mr-4">
                  <RadioButton
                    :disabled="props.params.loading"
                    id="bankAccountNameType1"
                    :value="1"
                    v-model="props.data.bank_account_name_type"
                  />
                  <label for="bankAccountNameType1">Legal Name</label>
                </div>
                <div class="p-field-radiobutton mr-4">
                  <RadioButton
                    :disabled="props.params.loading"
                    id="bankAccountNameType2"
                    :value="2"
                    v-model="props.data.bank_account_name_type"
                  />
                  <label for="bankAccountNameType2">DBA</label>
                </div>
                <div class="p-field-radiobutton">
                  <RadioButton
                    :disabled="props.params.loading"
                    id="bankAccountNameType3"
                    :value="3"
                    v-model="props.data.bank_account_name_type"
                  />
                  <label for="bankAccountNameType3">Other</label>
                </div>
              </div>
            </div>
            <div class="grid mx-0 mb-4">
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field flex flex-column">
                  <label for="accountName" class="mb-2"
                    >Name on Bank Account</label
                  >
                  <InputText
                    id="accountName"
                    data-private
                    type="text"
                    v-model="props.data.bank_account_name"
                    :disabled="props.params.loading"
                    :class="{
                      'p-invalid': props.params.errors.bank_account_name,
                    }"
                    aria-describedby="bank_account_name_help"
                  />
                  <small
                    id="bank_account_name_help"
                    v-if="props.params.errors.bank_account_name"
                    class="p-error"
                    >{{ props.params.errors.bank_account_name[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="flex flex-column mb-5 px-0 lg:px-2">
              <p class="mt-0">Account Type:</p>
              <div class="flex">
                <div class="p-field-radiobutton mr-4">
                  <RadioButton
                    id="bankAccountType1"
                    :value="1"
                    v-model="props.data.bank_account_type"
                  />
                  <label for="bankAccountType1">Checking</label>
                </div>
                <div class="p-field-radiobutton">
                  <RadioButton
                    id="bankAccountType2"
                    :value="2"
                    v-model="props.data.bank_account_type"
                  />
                  <label for="bankAccountType2">Savings</label>
                </div>
              </div>
            </div>
            <div class="grid mx-0">
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                  <label for="routingNo" class="mb-2">Routing Number</label>
                  <InputText
                    id="routingNo"
                    data-private
                    type="text"
                    v-model="props.data.routing_number"
                    :disabled="props.params.loading"
                    maxlength="9"
                    :class="{ 'p-invalid': props.params.errors.routing_number }"
                    aria-describedby="routing_number_help"
                  />
                  <small
                    id="routing_number_help"
                    v-if="props.params.errors.routing_number"
                    class="p-error"
                    >{{ props.params.errors.routing_number[0] }}</small
                  >
                </div>
              </div>
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-4 flex flex-column">
                  <label for="accountNo" class="mb-2">Account Number</label>
                  <InputText
                    id="accountNo"
                    data-private
                    type="text"
                    v-model="props.data.account_number"
                    :disabled="props.params.loading"
                    :class="{ 'p-invalid': props.params.errors.account_number }"
                    aria-describedby="account_number_help"
                  />
                  <small
                    id="account_number_help"
                    v-if="props.params.errors.account_number"
                    class="p-error"
                    >{{ props.params.errors.account_number[0] }}</small
                  >
                </div>
              </div>
            </div>
            <div class="flex flex-column mb-5 px-0 lg:px-2">
              <p class="mt-0">This account will be used for the following:</p>
              <div class="flex">
                <div class="p-field-checkbox mr-4">
                  <Checkbox
                    :binary="true"
                    id="isDeposit"
                    v-model="props.data.is_deposit"
                  />
                  <label for="isDeposit">Deposits</label>
                </div>
                <div class="p-field-checkbox mr-4">
                  <Checkbox
                    :binary="true"
                    id="isFees"
                    v-model="props.data.is_fees"
                  />
                  <label for="isFees">Fees</label>
                </div>
                <div class="p-field-checkbox">
                  <Checkbox
                    :binary="true"
                    id="isChargebacks"
                    v-model="props.data.is_chargebacks"
                  />
                  <label for="isChargebacks">Chargebacks</label>
                </div>
              </div>
            </div>
            <div class="grid mx-0">
              <div class="col-12 lg:col-3 px-0 lg:px-2">
                <div class="flex flex-column">
                  <div class="step-header mb-2">Voided Check</div>
                  <div v-if="!fileName" class="gray-text">
                    Please take a picture of a voided check and upload it.
                  </div>
                  <div v-if="fileName" class="gray-text">{{ fileName }}</div>
                </div>
              </div>
              <div class="col-12 lg:col-3 lg:col-offset-1 px-0 lg:px-2 flex">
                <div class="voided-check-upload-btn flex">
                  <FileUpload
                    :class="
                      props.data.check
                        ? 'hidden'
                        : 'pi pi-upload voided-check-upload-btn-icon'
                    "
                    cancelLabel="Cancel"
                    :showUploadButton="false"
                    :showCancelButton="!!props.data.check"
                    accept="application/pdf, image/jpeg, image/png"
                    ref="file"
                    chooseLabel="Upload Voided Check"
                    :customUpload="true"
                    @select="
                      props.data = this.handleFileUpload($event, props.data)
                    "
                    @clear="props.data = this.handleClearFileUpload(props.data)"
                  />
                </div>
              </div>
            </div>
            <small v-if="props.params.errors.check" class="p-error">{{
              props.params.errors.check[0]
            }}</small>
          </div>
        </div>
      </div>
    </div>
  </full-screen-modal-with-header>
</template>

<script>
import FullScreenModalWithHeader from '@/components/FullScreenModalWithHeader';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import FormSteps from './FormSteps';
import FileUpload from 'primevue/fileupload';

export default {
  components: {
    FullScreenModalWithHeader,
    InputText,
    Checkbox,
    RadioButton,
    FormSteps,
    FileUpload,
  },
  props: {
    application: Object,
    activeForm: String,
    isActive: Boolean,
  },
  data() {
    return {
      fileName: null,
    };
  },
  methods: {
    handleClearFileUpload(data) {
      this.fileName = null;
      delete data.check;

      return data;
    },

    handleFileUpload(event, data) {
      data.check = event.files[0];
      this.fileName = event.files[0].name;
      console.debug(
        'handleFileUpload file data',
        data.check.constructor.name,
        data.check
      );

      return data;
    },
  },
};
</script>
