<template>
  <div class="grid">
    <div class="col-6 col-offset-3 p-fluid">
      <form @submit="handleInvite">
        <span class="p-float-label">
          <InputText
            id="email"
            :class="{ 'p-invalid': errors && errors['email'] }"
            type="email"
            v-model="email"
          />
          <label for="email">Email</label>
          <small
            v-if="errors && errors['email']"
            id="email-help"
            class="p-error"
            >{{ errors['email'] }}</small
          >
        </span>
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="first_name"
              :class="{ 'p-invalid': errors && errors['name'] }"
              v-model="first_name"
            />
            <label for="first_name">First name</label>
            <small
              v-if="errors && errors['name']"
              id="first_name-help"
              class="p-error"
              >{{ errors['name'] }}</small
            >
          </span>
        </div>
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="last_name"
              :class="{ 'p-invalid': errors && errors['last_name'] }"
              v-model="last_name"
            />
            <label for="email">Last name</label>
            <small
              v-if="errors && errors['last_name']"
              id="last_name-help"
              class="p-error"
              >{{ errors['last_name'] }}</small
            >
          </span>
        </div>
        <Button :disabled="loading" type="submit" class="mt-3" label="Invite" />
        <Message severity="error" v-if="error">{{ error }}</Message>
        <Message severity="success" v-if="success"
          >Invitation sent successfully</Message
        >
      </form>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InviteUser from '../services/api-calls/invite.service';

export default {
  components: {
    InputText,
    Button,
    Message,
  },

  data() {
    return {
      loading: false,
      success: false,
      error: '',
      errors: [],
      email: '',
      first_name: null,
      last_name: null,
    };
  },

  methods: {
    async handleInvite(e) {
      e.preventDefault();
      this.loading = true;
      this.success = false;
      this.error = '';
      this.errors = [];

      const result = await InviteUser.invite(
        this.email,
        this.first_name,
        this.last_name
      );

      this.error = result.error ?? '';
      this.errors = result.errors ?? [];

      if (result.success) {
        this.success = true;
        this.email = '';

        this.$emit('invited');
      }
      this.loading = false;
    },
  },
};
</script>
