<template>
  <h3 class="mb-3">Equipment</h3>

  <div class="flex lg:hidden">
    <div class="col-6">
      <Button
        @click="addItemModal = true"
        label="Add Equipment"
        class="col-12 p-button-outlined"
      />
    </div>
    <div v-if="items.length" class="col-6">
      <Button
        @click="clearAll"
        label="Clear All"
        class="col-12 p-button-outlined"
      />
    </div>
  </div>

  <div class="lg:hidden" id="mobile-cart">
    <div
      v-for="(item, index) in items"
      v-bind:key="item.id"
      class="grid m-0 items"
    >
      <div v-if="item.id" class="col-8 pt-3 pb-3">{{ item.name }}</div>
      <div v-if="item.id" class="col-1 mobile-cart-qty pt-3 pb-3">
        {{ item.qty }}
      </div>
      <div v-if="item.id" class="col-2 mobile-cart-price pt-3 pb-3">
        {{ formatPrice(item.price) }}
      </div>
      <div v-if="item.id" class="col-1 mobile-cart-edit pt-3 pb-3">
        <i
          @click="editProductModal(item, index)"
          class="pi pi-pencil cursor-pointer"
        ></i>
      </div>
    </div>
    <div id="mobile-cart-totals" class="col-12 grid m-0 p-0">
      <div class="col-8 pt-3">Subtotal</div>
      <div class="col-4 pt-3 mobile-totals">
        {{ formatPrice(order_subtotal) }}
      </div>
      <div class="col-8 pb-3" v-if="is_pay_in_full">
        Tax ({{ tax_rate_string }}%)
      </div>
      <div class="col-4 mobile-totals pb-3" v-if="is_pay_in_full">
        {{ formatPrice(order_tax) }}
      </div>
      <div class="mobile-cart-total col-8 pt-3 pb-3">Total</div>
      <div class="mobile-cart-total col-4 mobile-totals pt-3 pb-3">
        {{ formatPrice(order_total) }}
      </div>
    </div>
  </div>

  <div v-if="isLoading" class="text-center"><progress-spinner /></div>

  <DataTable
    id="order-cart"
    v-else
    :value="items"
    columnResizeMode="fit"
    showGridlines
    editMode="cell"
    @cell-edit-complete="onCellEditComplete"
    class="hidden lg:block mt-2 editable-cells-table"
  >
    <Column class="pl-2" field="category" header="Category">
      <template #body="slotProps">
        <div
          class="p-2 cursor-pointer cart-button-block"
          @click="
            currentIndex = slotProps.index;
            this.$refs.categories.toggle($event);
          "
        >
          <span v-if="!slotProps.data.category_id && isEdit">Change Item</span>
          <span v-else-if="!slotProps.data.category_id">Select Category</span>
          <span v-else>{{ slotProps.data.category }}</span>
          <span
            class="p-dropdown-trigger-icon pi pi-chevron-down cart-list-button"
          ></span>
        </div>
      </template>
    </Column>

    <Column field="name" header="Item">
      <template #body="slotProps">
        <div class="p-2" v-if="items[slotProps.index].category_id">
          <div @click="showProducts2($event, slotProps.index)">
            <div v-if="productsLoading === slotProps.index">
              <progress-spinner style="width: 15px; height: 15px" />
            </div>
            <div class="pl-2 pt-1 pb-1 cursor-pointer cart-button-block">
              <span
                v-if="!slotProps.data.id && productsLoading !== slotProps.index"
                >Select Item</span
              >
              <span v-if="slotProps.data.id">{{ slotProps.data.name }}</span>
              <span
                class="p-dropdown-trigger-icon pi pi-chevron-down cart-list-button"
              ></span>
            </div>
          </div>
        </div>
        <div class="p-2" v-else-if="isEdit && items[slotProps.index].name">
          <span>{{ slotProps.data.name }}</span>
        </div>
      </template>
    </Column>

    <Column class="pl-3 col-2" field="qty" header="Quantity">
      <template #editor="{ data, field }">
        <InputNumber
          :min="1"
          :step="1"
          inputStyle="width: 100px"
          v-model="data[field]"
          autofocus
        />
      </template>
    </Column>

    <Column field="price" header="Price">
      <template #body="slotProps">
        <div v-if="slotProps.data.price" class="p-2">
          {{ formatPrice(slotProps.data.price) }}
        </div>
      </template>
    </Column>

    <Column field="total" header="Total">
      <template #body="slotProps">
        <div v-if="slotProps.data.price" class="p-2 cart-button-block">
          {{ formatPrice(slotProps.data.price * slotProps.data.qty) }}
          <Button
            @click="deleteItem(slotProps.index)"
            icon="pi pi-times"
            class="cart-delete-button p-button-rounded mb-2"
          />
        </div>
      </template>
    </Column>

    <ColumnGroup type="footer">
      <Row>
        <Column :colspan="2" class="footer-table-cell">
          <template #footer>
            <Button
              label="Add Item"
              @click="addItem"
              class="p-button-outlined mr-2 mb-2 mt-2"
            />
            <Button
              label="Clear all"
              @click="clearAll"
              class="p-button-outlined mr-2 mb-2 mt-2"
            />
          </template>
        </Column>

        <Column :colspan="3" class="p-0 cart-totals">
          <template #footer>
            <div class="grid p-2 pb-1">
              <div class="col-8">Subtotal</div>
              <div class="col-4">{{ formatPrice(order_subtotal) }}</div>
            </div>
            <div class="grid p-2" v-if="is_pay_in_full">
              <div class="col-8">Tax ({{ tax_rate_string }}%)</div>
              <div class="col-4">{{ formatPrice(order_tax) }}</div>
            </div>
            <div class="grid m-0 cart-total-cell">
              <div class="col-8">Total</div>
              <div class="col-4">{{ formatPrice(order_total) }}</div>
            </div>
          </template>
        </Column>
      </Row>
    </ColumnGroup>
  </DataTable>

  <OverlayPanel ref="categories">
    <div class="mt-0" v-if="categories.length">
      <p class="pt-0 mb-2 mt-0"><strong>CATEGORY LIST</strong></p>
      <div
        class="pb-3 cursor-pointer"
        v-for="category in categories"
        v-bind:key="category.id"
      >
        <span @click="selectCategory(category.id, category.name)">{{
          category.name
        }}</span>
      </div>
    </div>
    <div v-else>
      <p class="pt-0 mb-2 mt-0"><strong>No categories found</strong></p>
    </div>
  </OverlayPanel>
  <OverlayPanel ref="products">
    <div
      class="mt-0"
      v-if="
        items[currentIndex].category_id &&
        this.products[this.items[currentIndex].category_id] !== undefined &&
        products[items[currentIndex].category_id].length
      "
    >
      <p class="pt-0 mb-2 mt-0"><strong>ITEMS LIST</strong></p>
      <div
        class="pb-3 cursor-pointer"
        v-for="product in products[items[currentIndex].category_id]"
        v-bind:key="product.id"
      >
        <span @click="selectProduct(product)">{{ product.name }}</span>
      </div>
    </div>
    <div v-else>
      <p class="pt-0 mb-2 mt-0"><strong>No items found</strong></p>
    </div>
  </OverlayPanel>

  <Dialog
    v-model:visible="addItemModal"
    @hide="newItem = {}"
    :breakpoints="{ '960px': '75vw', '769px': '95vw' }"
    :style="{ width: '60vw' }"
  >
    <template #header>
      <span class="p-dialog-title">Add Equipment</span>
    </template>
    <div class="p-field mb-2 flex flex-column">
      <label class="mb-2">Category</label>
      <Dropdown
        v-model="newItem.category"
        :options="categories"
        @change="selectCategoryModal()"
        optionLabel="name"
        inputId="application-filters"
        placeholder="Select category"
        class="lg:mr-4 mb-3 lg:mb-0 w-full md:w-auto"
      />
    </div>
    <div class="p-field mb-2 flex flex-column">
      <label class="mb-2">Item</label>
      <div v-if="productsLoading === 'modal'">
        <progress-spinner style="width: 15px; height: 15px" />
      </div>
      <Dropdown
        v-else-if="newItem.category"
        v-model="newItem.product"
        :options="products[newItem.category.id] ?? []"
        optionLabel="name"
        inputId="application-filters"
        placeholder="Select Item"
        class="lg:mr-4 mb-3 lg:mb-0 w-full md:w-auto"
      />
    </div>
    <div class="field">
      <label class="mr-4" for="newItemQty"><strong>Quantity:</strong></label>
      <InputNumber
        id="newItemQty"
        v-model="newItem.qty"
        showButtons
        buttonLayout="horizontal"
        :step="1"
        decrementButtonClass="p-button-secondary"
        incrementButtonClass="p-button-secondary"
        :min="0"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        mode="decimal"
      />
    </div>
    <div class="field grid m-0" v-if="newItem.product && newItem.qty">
      <label class="mr-4 col-3 p-0"><strong>Price:</strong></label>
      <p class="col-8 p-0 text-right">
        <strong>{{ formatPrice(newItem.product.price * newItem.qty) }}</strong>
      </p>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button
          @click="
            addItemModal = false;
            newItem = {};
          "
          label="Cancel"
          class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"
        />
        <Button
          :label="newItem.index !== undefined ? 'Save' : 'Add item'"
          :disabled="!newItem.product || !newItem.category || !newItem.qty"
          @click="addProductModal()"
          class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import Helper from '@/services/helper';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import ProductsService from '@/services/api-calls/products.service';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import OverlayPanel from 'primevue/overlaypanel';
import Dialog from 'primevue/dialog';

export default {
  name: 'Cart',

  components: {
    ProgressSpinner,
    DataTable,
    Button,
    Column,
    InputNumber,
    Dropdown,
    ColumnGroup,
    Row,
    OverlayPanel,
    Dialog,
  },

  props: {
    equipment: Array,
    isEdit: Boolean,
    paymentMethod: String,
    salesTaxRate: Number,
  },

  emits: ['update:equipment'],

  data() {
    return {
      newItem: {},
      error: null,
      categories: [],
      products: [],
      currentIndex: null,
      items: this.equipment.length ? this.equipment : [],
      isLoading: false,
      addItemModal: false,
      productsLoading: null,
    };
  },

  async mounted() {
    await this.getCategories();
  },

  watch: {
    items: {
      handler() {
        console.debug('items changed');
        this.$emit('update:equipment', this.items);
      },
      deep: true,
    },

    paymentMethod(new_payment_method) {
      console.debug('payment method changed');
      this.rePriceProducts(new_payment_method);
    },
  },

  computed: {
    is_pay_in_full() {
      console.debug('is_pay_in_full');
      return this.paymentMethod === 'full';
    },

    tax_rate_string() {
      return this.salesTaxRate ? this.salesTaxRate.toString() : '0';
    },

    tax_rate_number() {
      return this.salesTaxRate ? this.salesTaxRate / 100 : 0;
    },

    order_totals() {
      let subtotal = 0;
      let total = 0;
      for (const [, item] of Object.entries(this.items)) {
        if (item.price) subtotal += item.price * item.qty;
      }
      const tax = subtotal * this.tax_rate_number;
      total = subtotal;
      if (this.paymentMethod === 'full') total += tax;

      return {
        subtotal: subtotal,
        total: total,
        tax: tax,
      };
    },

    order_subtotal() {
      return this.order_totals.subtotal;
    },

    order_tax() {
      return this.order_totals.tax;
    },

    order_total() {
      return this.order_totals.total;
    },
  },

  methods: {
    addItem() {
      console.debug('addItem');
      this.items.push({});
    },

    async getCategories() {
      console.debug('getCategories');
      this.isLoading = true;
      const data = await ProductsService.getAllCategories();
      this.categories = data.items ?? [];
      this.isLoading = false;
    },

    showProducts2(event, index) {
      console.debug('showProducts2');
      this.loadCategoryProducts(this.items[index].category_id, index);
      this.currentIndex = index;
      this.$refs.products.toggle(event);
    },

    async showProducts(event, index) {
      console.debug('showProducts');
      this.currentIndex = index;
      this.$refs.products.toggle(event);
      if (this.products[this.items[index].category_id] === undefined) {
        await this.loadCategoryProducts(this.items[index].category_id, index);
      }
    },

    async selectCategory(categoryId, name) {
      console.debug('selectCategory');
      this.items[this.currentIndex] = {
        category_id: categoryId,
        category: name,
      };
      this.$refs.categories.hide();
      const index = this.currentIndex;
      this.currentIndex = null;

      if (this.items[index].category_id) {
        await this.loadCategoryProducts(this.items[index].category_id, index);
      }
    },

    async selectCategoryModal() {
      console.debug('selectCategoryModal');
      this.newItem = {
        category: this.newItem.category,
        qty: this.newItem.qty,
        index: this.newItem.index,
      };

      if (this.newItem.category) {
        await this.loadCategoryProducts(this.newItem.category.id, 'modal');
      }
    },

    async loadCategoryProducts(category_id, index) {
      console.debug('loadCategoryProducts');
      if (this.products[category_id] === undefined) {
        this.productsLoading = index;
        const data = await ProductsService.getByCategory(category_id);
        this.products[category_id] = data.items ?? [];
        this.productsLoading = null;
      }
    },

    selectProduct(product) {
      console.debug('selectProduct');
      this.addProduct(product, this.currentIndex);

      this.$refs.products.hide();
      this.currentIndex = null;
    },

    addProductModal() {
      console.debug('addProductModal');
      if (
        this.newItem &&
        this.newItem.product &&
        this.newItem.category &&
        this.newItem.qty
      ) {
        const index =
          this.newItem.index !== undefined
            ? this.newItem.index
            : this.items.length;
        let product = this.newItem.product;
        product.category = this.newItem.category.name;
        product.category_id = this.newItem.category.id;

        this.addProduct(product, index, this.newItem.qty);

        this.addItemModal = false;
        this.newItem = {};
      }
    },

    editProductModal(item, index) {
      console.debug('editProductModal');
      if (item.category_id) {
        this.newItem = {
          index: index,
          product: this.products[item.category_id].find((product) => {
            return item.id === product.id;
          }),
          category: this.categories.find((category) => {
            return item.category_id === category.id;
          }),
          qty: item.qty,
        };
      } else {
        this.newItem = {
          index: index,
          qty: item.qty,
        };
      }
      this.addItemModal = true;
    },

    addProduct(product, index, qty = 1) {
      console.debug('addProduct');
      if (this.items[index] === undefined) {
        this.items[index] = {
          category: product.category,
          category_id: product.category_id,
        };
      }
      this.items[index].name = product.name;

      if (this.paymentMethod === 'full') {
        this.items[index].price = product.price_pif;
      } else if (this.paymentMethod === 'lease') {
        this.items[index].price = product.price_lease;
      } else {
        throw new Error('Invalid payment method selection during add product');
      }
      this.items[index].price_pif = product.price_pif;
      this.items[index].price_lease = product.price_lease;
      this.items[index].id = product.id;

      const alreadyAdded = this.items.findIndex((item) => {
        return item.id === product.id;
      });

      if (alreadyAdded > -1 && alreadyAdded !== index) {
        this.items[index].qty = this.items[alreadyAdded].qty + qty;
        this.deleteItem(alreadyAdded);
      } else {
        this.items[index].qty = qty;
      }
    },

    rePriceProducts(new_payment_method) {
      console.debug('rePriceProducts');
      for (const item of this.items) {
        if (new_payment_method === 'full') {
          item.price = item.price_pif;
        } else if (new_payment_method === 'lease') {
          item.price = item.price_lease;
        } else {
          throw new Error('Invalid payment method selection during reprice');
        }
      }
    },

    formatPrice(value) {
      return Helper.formatPrice(value);
    },

    onCellEditComplete(event) {
      this.items[event.index]['qty'] = event.newData['qty'];
    },

    deleteItem(index) {
      this.items.splice(index, 1);
    },

    clearAll() {
      this.items = [];
    },
  },
};
</script>
