<template>
  <merchant-app v-if="application" :merchant-application="application" />
  <div v-if="loading" class="text-center"><ProgressSpinner /></div>
  <Message severity="error" v-if="error">{{ error }}</Message>
</template>

<script>
import MerchantApp from '@/components/Application/Main';
import ProgressSpinner from 'primevue/progressspinner';
import ApplicationService from '@/services/api-calls/application.service';
import Message from 'primevue/message';

export default {
  name: 'Customer application',
  components: {
    MerchantApp,
    ProgressSpinner,
    Message,
  },

  data() {
    return {
      application: null,
      loading: true,
      error: null,
    };
  },

  computed: {
    currentUserRole() {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user.role) ||
        null
      );
    },
  },

  created() {
    if ('client' === this.currentUserRole) {
      this.getApp();
    } else {
      this.$router.push('/applications');
    }
  },

  methods: {
    async getApp() {
      this.loading = true;
      const data = await ApplicationService.getFirst();
      if (data.success) {
        this.application = data.application;
      } else {
        this.error = data.error;
      }
      this.loading = false;
    },
  },
};
</script>
