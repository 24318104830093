export const settings = {
  namespaced: true,
  state: {
    dev_mode: false,
  },
  getters: {
    devMode: (state) => state.dev_mode,
  },
  actions: {
    setDevMode({ commit }, bool) {
      commit('SET_DEV_MODE', bool);
    },
  },
  mutations: {
    SET_DEV_MODE(state, bool) {
      state.dev_mode = bool;
    },
  },
};
