<template>
  <div v-if="loading" class="text-center"><ProgressSpinner /></div>
  <Message severity="error" v-if="error">{{ error }}</Message>
  <div
    v-if="application"
    id="new-merchant-app-mobile-steps-list"
    class="flex flex-column flex-1"
  >
    <div v-if="'client' !== currentUserRole">
      <div class="heading mb-5">
        Complete the following steps to submit your application for review.
      </div>
      <div class="grid">
        <div class="col-12 lg:col-4 flex">
          <step-card>
            <div class="step-card-header">
              <div class="step-card-status">
                {{
                  this.isComplete('businessContacts')
                    ? 'Complete'
                    : 'Incomplete'
                }}
              </div>
              <div>
                <img
                  alt="new merchant app"
                  class="step-card-icon"
                  src="../../assets/images/icon-merchant-application@2x.png"
                />
              </div>
              <div class="step-card-title">
                <h2 class="mt-3">Merchant Application</h2>
              </div>
            </div>
            <div class="step-card-body">
              <p class="gray-text step-card-text">
                Complete this application with the merchant or email them a link
                to complete it. Note that this form includes proprietary
                information (e.g., SSN, EIN) and you will need to upload a copy
                of a voided check.
              </p>
            </div>
            <div class="step-card-footer flex mt-4">
              <Button
                v-if="'client' !== currentUserRole"
                @click="showForm('send-email')"
                label="Email to Merchant"
                class="p-button-outlined flex-1 mr-2 p-button-lg"
              />
              <Button
                @click="showForm('general')"
                label="Launch App"
                class="flex-1 ml-2 p-button-lg"
              />
            </div>
          </step-card>
        </div>
        <div class="col-12 lg:col-4 flex">
          <step-card>
            <div class="step-card-header">
              <div class="step-card-status">
                {{ this.isComplete('survey') ? 'Complete' : 'Incomplete' }}
              </div>
              <div>
                <img
                  alt="new merchant app"
                  class="step-card-icon"
                  src="../../assets/images/icon-site-survey@2x.png"
                />
              </div>
              <div class="step-card-title">
                <h2 class="mt-3">Location/ID Verification</h2>
              </div>
            </div>
            <div class="step-card-body">
              <p class="gray-text step-card-text">
                Merchants must be verified via a site survey or phone
                verification. Verify a valid government-issued ID and that the
                inventory matches the products sold.
              </p>
            </div>
            <div class="step-card-footer flex mt-4">
              <Button
                @click="showForm('survey')"
                :disabled="!this.isComplete('business')"
                label="Complete Site Survey"
                class="flex-1 p-button-lg"
              />
            </div>
          </step-card>
        </div>
        <div class="col-12 lg:col-4 flex">
          <step-card>
            <div class="step-card-header">
              <div class="step-card-status">
                {{ this.isComplete('order') ? 'Complete' : 'Incomplete' }}
              </div>
              <div>
                <img
                  alt="new merchant app"
                  class="step-card-icon"
                  src="../../assets/images/icon-equipment@2x.png"
                />
              </div>
              <div class="step-card-title">
                <h2 class="mt-3">Order & Configure Equipment</h2>
              </div>
            </div>
            <div class="step-card-body">
              <p class="gray-text step-card-text">
                Select the credit card reader (make/model) and quantity needed.
                Once the order is placed, configure the equipment based on the
                merchant’s needs.
              </p>
            </div>
            <div class="step-card-footer flex mt-4">
              <Button
                v-if="!dev_mode"
                label="Enable dev mode to use"
                class="flex-1 p-button-lg p-button-secondary p-button-text"
              ></Button>
              <Button
                v-if="!!dev_mode"
                @click="showForm('equipment')"
                :disabled="!this.isComplete('business')"
                label="Order Equipment"
                class="flex-1 p-button-lg"
              />
            </div>
          </step-card>
        </div>
      </div>
    </div>
    <div v-if="'client' === currentUserRole">
      <Button label="Reopen Application" @click="showForm('general')"></Button>
    </div>
    <email-application-modal
      v-if="merchantNumber"
      @close="closeForm()"
      :merchant-number="merchantNumber"
      :is-open="'send-email' === activeForm"
    />
    <forms
      :active-form="activeForm"
      :application="application"
      @close="closeForm"
      @open="showForm($event)"
      @update="updateApp($event)"
    />
  </div>
</template>
<script>
import Button from 'primevue/button';
import StepCard from '@/components/NewMerchantApplication/StepCard';
import EmailApplicationModal from '@/components/NewMerchantApplication/EmailApplicationModal';
import Forms from '@/components/Application/Forms/Forms';
import ProgressSpinner from 'primevue/progressspinner';
import ApplicationService from '@/services/api-calls/application.service';
import Message from 'primevue/message';

export default {
  components: {
    StepCard,
    Button,
    Forms,
    ProgressSpinner,
    Message,
    EmailApplicationModal,
  },

  props: {
    merchantNumber: {
      type: Number,
      required: false,
    },
    merchantApplication: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      error: null,
      activeForm: null,
      application: null,
      loading: false,
    };
  },

  computed: {
    currentUserRole() {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user.role) ||
        null
      );
    },
    dev_mode() {
      return this.$store.getters['settings/devMode'];
    },
  },

  created() {
    if (this.merchantApplication) {
      this.application = this.merchantApplication;
    } else if (this.merchantNumber) {
      this.getApp();
    }
  },

  mounted() {
    if ('client' === this.currentUserRole) this.showForm('general');
  },

  methods: {
    isComplete(step) {
      if (
        this.application &&
        this.application.completed_steps &&
        this.application.completed_steps.completed_forms
      ) {
        return this.application.completed_steps.completed_forms.includes(step);
      }
      return false;
    },
    showForm(from) {
      console.debug('Showing form:', from);
      this.activeForm = from;
    },
    closeForm() {
      this.activeForm = null;
      return false;
    },
    async getApp() {
      this.loading = true;
      const result = await ApplicationService.get(this.merchantNumber);
      if (result.merchant_number) {
        this.application = result;
      }
      if (result.message || result.error) {
        this.error = result.message ? result.message : result.error;
      }
      this.loading = false;
    },
    updateApp(app) {
      this.application = { ...this.application, ...app };
    },
  },
};
</script>
