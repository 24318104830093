import ApiService from '../api.service';

class Orders {
  async getAll(itemsPerPage = 10, currentPage = 1, filter = []) {
    return await ApiService.request('get', 'order/', {
      per_page: itemsPerPage,
      page: currentPage,
      'filter[name]': filter.name,
      'filter[value]': filter.value,
    });
  }

  async get(orderId) {
    return await ApiService.request('get', 'order/' + orderId, {});
  }

  async getQuote(orderId) {
    return await ApiService.request('get', 'order/' + orderId + '/quote', {
      target: process.env.VUE_APP_URL,
      server_domain: process.env.VUE_APP_BACKEND_URL,
    });
  }

  async sendQuote(orderId) {
    return await ApiService.request(
      'post',
      'order/' + orderId + '/quote/send',
      {
        target: process.env.VUE_APP_URL,
        server_domain: process.env.VUE_APP_BACKEND_URL,
      }
    );
  }

  async downloadQuote(orderId) {
    return await ApiService.downloadRequest(
      'get',
      'order/' + orderId + '/quote',
      {
        target: process.env.VUE_APP_URL,
        server_domain: process.env.VUE_APP_BACKEND_URL,
        pdf: 1,
      },
      'application/pdf',
      'quote_' + orderId
    );
  }

  async setStatus(orderId, status) {
    return await ApiService.request(
      'patch',
      'order/' + orderId + '/status/' + status,
      {}
    );
  }

  async create(order) {
    return await ApiService.request('post', 'order/', order);
  }

  async update(orderId, order) {
    return await ApiService.request('patch', 'order/' + orderId, order);
  }

  async getTaxRate({ country = 'US', state, zip, city, street } = {}) {
    if (!country) {
      throw new Error('Country is required to calcualte sales tax');
    }
    if (!state) {
      throw new Error('State is required to calcualte sales tax');
    }
    if (!zip) {
      throw new Error('Zip is required to calcualte sales tax');
    }
    if (!city) {
      throw new Error('City is required to calcualte sales tax');
    }
    if (!street) {
      throw new Error('Street is required to calcualte sales tax');
    }
    return await ApiService.request('post', 'tax_rates', {
      country: 'US',
      state: state,
      city: city,
      zip: zip,
      street: street,
    });
  }
}

export default new Orders();
