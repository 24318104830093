<template>
  <div id="subheader-text">Welcome to Parsepay</div>
  <div id="header-text" class="mb-4">
    Sign in to the<br />
    {{ name_of_portal }}
  </div>
  <Message severity="error" v-if="error">{{ error }}</Message>
  <form id="login-form" class="auth-card" @submit="handleLogin">
    <div class="p-field mb-3">
      <InputText
        id="username"
        placeholder="Username"
        type="text"
        v-model="username"
      />
    </div>
    <div class="p-field mb-4">
      <InputText
        id="password"
        placeholder="Password"
        type="password"
        autocomplete="on"
        v-model="password"
      />
    </div>
    <Button
      :disabled="loading"
      type="submit"
      class="mb-3 btn-blue"
      label="Log In"
    />
    <div v-if="!isCustomer" class="helper-text-2 text-center">
      Forgot your login information?
      <router-link to="/forgot-password" class="no-underline"
        >Get help signing in.</router-link
      >
    </div>
  </form>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';

export default {
  name: 'Login',
  props: {
    isCustomer: Boolean,
  },
  components: {
    InputText,
    Button,
    Message,
  },
  data() {
    return {
      loading: false,
      error: '',
      username: '',
      password: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    name_of_portal() {
      if (this.isCustomer) {
        return 'Application Portal';
      } else {
        return 'Hardware Orders Portal';
      }
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin(e) {
      e.preventDefault();
      this.loading = true;
      this.error = '';

      this.$store
        .dispatch('auth/login', {
          user: {
            username: this.username,
            password: this.password,
          },
          isCustomer: this.isCustomer,
        })
        .then(
          () => {
            this.checkLogin();
            this.isCustomer
              ? this.$router.push('/application')
              : this.$router.push('/');
          },
          (error) => {
            this.loading = false;
            this.error =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              'Invalid credentials.';
          }
        );
    },
    checkLogin() {
      this.$store.dispatch('logrocket/identifyLogRocket');
    },
  },
};
</script>
