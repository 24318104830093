<template>
  <Dialog
    id="add-equipment-modal-2"
    header="Add Equipment"
    v-model:visible="isOpen"
    :breakpoints="{ '1400px': '50vw', '960px': '75vw', '769px': '95vw' }"
    :style="{ width: '40vw' }"
    @hide="hide()"
  >
    <template #header>
      <span class="p-dialog-title">Equipment configuration</span>
      <div class="flex">
        <ProgressBar
          v-if="isLoading"
          style="
            position: absolute;
            top: 2px;
            left: 0px;
            height: 4px;
            width: 100%;
          "
          mode="indeterminate"
        />
      </div>
    </template>

    <div v-if="questions.length" class="grid m-0 flex flex-1">
      <div class="col-12 px-0 lg:px-2 mb-2 lg:mb-3">
        <div class="step-header">Equipment configuration</div>
      </div>
      <div v-for="questionsGroup in questions" v-bind:key="questionsGroup.area">
        <questions
          @updateConfiguration="
            this.updateConfiguration($event, questionsGroup.area)
          "
          :questions="questionsGroup"
          :errors="errors"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-content-start">
        <small v-if="error" class="p-error">{{ error }}</small>
      </div>
      <div class="flex justify-content-end">
        <Button
          @click="hide()"
          :disabled="isLoading"
          label="Cancel"
          class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"
        />
        <Button
          @click="submit()"
          :disabled="isLoading"
          :label="orderId ? 'Resubmit' : 'Add'"
          class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import EquipmentsService from '@/services/api-calls/equipments.service';
import FormHelper from '@/services/form.helper';
import ProgressBar from 'primevue/progressbar';

export default {
  name: 'EquipmentConfiguration',
  components: {
    Button,
    Dialog,
    ProgressBar,
  },
  updated() {
    if (this.isActive !== this.isOpen) {
      this.isOpen = this.isActive;
    }
  },
  props: {
    isActive: Boolean,
    merchantNumber: {
      type: Number,
      required: true,
    },
    orderId: {
      type: String,
      required: false,
    },
    equipmentOrder: {
      type: Object,
      required: false,
      validator(equipment) {
        return equipment.equipment && equipment.quantity;
      },
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.getTemplate();
      }
    },
    equipmentOrder() {
      if (this.isOpen) {
        this.getTemplate();
      }
    },
  },
  methods: {
    updateConfiguration(configuration, area) {
      this.template.questionAnswerGroup.questionAnswerSets[area] = {
        ...configuration,
      };
    },
    hide() {
      if (!this.isLoading) {
        this.template = null;
        this.questions = [];
        this.$emit('close');
      }
    },
    async getTemplate() {
      this.isLoading = true;
      this.template = null;
      this.questions = [];
      this.error = null;
      let result = null;

      if (this.equipmentOrder) {
        result = await EquipmentsService.getTemplate(
          this.equipmentOrder.equipment
        );
      } else if (this.orderId) {
        result = await EquipmentsService.getOrder(
          this.merchantNumber,
          this.orderId
        );
        if (result.order) {
          result = result.order.orderItems[0].survey;
        }
      }
      if (result) {
        if (
          result.questionAnswerGroup &&
          result.questionAnswerGroup.questionAnswerSets
        ) {
          this.template = result;
          for (const [area, questions] of Object.entries(
            result.questionAnswerGroup.questionAnswerSets
          )) {
            this.questions.push({ area: area, questions: questions });
          }
        }
        if (result.error || result.message) {
          this.error = result.error
            ? FormHelper.convertGatewayError(result)
            : result.message;
        }
      }
      this.isLoading = false;
    },
    async submit() {
      this.isLoading = true;
      this.errors = {};
      this.error = null;
      let result = {};
      if (this.equipmentOrder) {
        let order = this.equipmentOrder;
        order.survey = { ...this.template };
        result = await EquipmentsService.submitOrder(this.merchantNumber, {
          ...order,
        });
      } else if (this.orderId) {
        result = await EquipmentsService.resubmitOrder(
          this.merchantNumber,
          this.orderId,
          { ...this.template }
        );
      }
      if (result.error || result.message) {
        this.error = result.error
          ? FormHelper.convertGatewayError(result)
          : result.message;
      }
      this.errors = result.errors ?? {};
      this.isLoading = false;
      if (result.application) {
        this.$emit('submit', result.application);
      }
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      error: null,
      template: [],
      questions: [],
      errors: {},
    };
  },
};
</script>
