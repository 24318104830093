<template>
  <div>
    <div
      id="sidebar"
      class="hidden lg:flex"
      :class="{ 'sidebar-collapsed': !sidebarIsOpen }"
    >
      <SidebarContents></SidebarContents>
    </div>
    <Sidebar class="mobile-sidebar" v-model:visible="sidebar_state">
      <SidebarContents></SidebarContents>
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from 'primevue/sidebar';
import SidebarContents from '../components/SidebarContents.vue';

export default {
  components: { SidebarContents, Sidebar },
  props: ['sidebarIsOpen', 'mobileSidebarIsOpen'],
  data() {
    return {
      sidebar_state: false,
    };
  },
  computed: {
    route() {
      return this.$route.path;
    },
  },
  watch: {
    mobileSidebarIsOpen: function (val) {
      this.sidebar_state = val;
    },
    sidebar_state: function (val) {
      if (val === true) return;
      this.$emit('update:closeMobileSidebar');
    },
    route: function (val) {
      console.debug('route changed', val);
      if (val.startsWith('/applications/')) {
        this.$emit('update:closeSidebar');
      }
    },
  },
  mounted() {
    this.sidebar_state = this.mobileSidebarIsOpen;
  },
};
</script>

<style lang="scss">
.p-sidebar.mobile-sidebar {
  background-color: #1C232E;
  color: #C0CBD0;

  .p-sidebar-content {
    min-height: calc(100vh - 64px);

    #nav-list {
      min-height: calc(100% - 64px);
      margin: 0 !important;
      margin-bottom: 64px !important;
    }
  }
}
</style>