export const merchantApplication = {
  namespaced: true,
  state: {
    application: {},
    general: null,
    business: null,
    profile: null,
    banking: null,
    contacts: null,
    disclosure: null,
  },
  getters: {
    getApplication: (state) => state.application,
  },
  actions: {
    setApplication({ commit }, application) {
      commit('SET_APPLICATION', application);
    },
  },
  mutations: {
    SET_APPLICATION(state, application) {
      state.application = application;
    },
  },
};
