<template>
  <Dialog
    id="email-application-modal"
    header="Email Application"
    v-model:visible="isVisible"
    :breakpoints="{
      '1600px': '500px',
      '1400px': '500px',
      '960px': '500px',
      '769px': '500px',
      '575px': '95vw',
    }"
    :style="{ width: '27vw' }"
    @hide="close()"
  >
    <template #header>
      <span class="p-dialog-title">Email Application</span>
      <div class="flex">
        <ProgressBar
          v-if="isLoading"
          style="
            position: absolute;
            top: 2px;
            left: 0px;
            height: 4px;
            width: 100%;
          "
          mode="indeterminate"
        />
      </div>
    </template>
    <div class="grid m-0 flex flex-1">
      <div class="col-12 px-0 lg:px-2 mb-0">
        <div class="step-header mb-2 lg:mb-2 line-height-3">
          Invite the merchant to fill out the application form directly.
        </div>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="p-field flex flex-column mb-3">
          <label for="email" class="mb-2">Merchant Email Address</label>
          <InputText
            id="email"
            type="text"
            placeholder="jane.doe@example.com"
            v-model="email"
            :disabled="isLoading"
            :class="{ 'p-invalid': errors.email }"
            aria-describedby="email_help"
          />
          <small id="email_help" v-if="errors.email" class="p-error">{{
            errors.email[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button
          :disabled="isLoading"
          @click="isVisible = false"
          label="Cancel"
          class="p-button-outlined p-button-lg hidden sm:block"
        />
        <Button
          :disabled="isLoading"
          @click="send()"
          label="Email App"
          class="p-button-lg sm:px-6 flex-1 sm:flex-initial"
        />
      </div>
    </template>
  </Dialog>
</template>
<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import InviteService from '@/services/api-calls/invite.service';
import ProgressBar from 'primevue/progressbar';

export default {
  name: 'EmailApplicationModal',
  components: {
    InputText,
    Button,
    Dialog,
    ProgressBar,
  },
  props: {
    isOpen: Boolean,
    merchantNumber: Number,
  },
  updated() {
    this.isVisible = this.isOpen;
  },
  methods: {
    close() {
      if (!this.isLoading) {
        this.$emit('close');
      }
    },
    async send() {
      this.isLoading = true;
      this.errors = {};
      this.error = null;
      const result = await InviteService.inviteParsePosUser(
        this.email,
        this.merchantNumber
      );
      if (result.success) {
        this.email = '';
        this.$emit('close');
      } else {
        this.error = result.error ?? null;
        this.errors = result.errors ?? {};
      }
      this.isLoading = false;
    },
  },
  data() {
    return {
      errors: {},
      error: null,
      isLoading: false,
      email: '',
      isVisible: false,
    };
  },
};
</script>
